import {
	AdsResponseType,
	AllBlogsRequestType,
	AllBlogsResponseType,
	AllChannelsResourceType,
	AllShortsResourceType,
	AnniversariesRequestType,
	AnniversariesResponseType,
	BirthdaysRequestType,
	BirthdaysResponseType,
	ChannelsByHashtagVTubeResourceType,
	ConnectedUserResource,
	EndorsmentByIdRequestType,
	ExclusiveChannelsResponseType,
	FeaturedChannelsResponseType,
	FeaturedVidoesResponseType,
	FolderFileRequestType,
	FolderFileResponseType,
	FolderRawResponseType,
	FolderRequestType,
	GeneralUserRequestType,
	GeneralUserResponseType,
	getAllBooksRequestType,
	getAllBooksResponseType,
	getAllConsultationsRequestType,
	GetAllEndorsmentsRequestType,
	GetAllEndorsmentsResponseType,
	getAllJobPostingsRequestType,
	getAllJobPostingsResponseType,
	getAllposdcastsResponseType,
	GetAllUserProfileRequestType,
	GetAllUserProfilesResponseType,
	getAllUsersRequestType,
	getAllUsersResponseType,
	GetAuthUserProfileInteractionsRequestType,
	getBookAuthorRequestType,
	getBookAuthorResponseType,
	getBookAuthorsRequestType,
	getCandidatesRequestType,
	getCandidatesResponseType,
	GetCartSummaryResponseType,
	GetConsultationServiceResponseType,
	GetConsultationTop10Response,
	GetCoursesTop10Response,
	GetEventDetailsByIdResponse,
	getJobEmployersRequestType,
	getJobEmployersResponseType,
	getmyJobsRequestType,
	getmyJobsResponseType,
	GetNotesResponseType,
	GetPublishedJobsRequestType,
	GetPublishedJobsResponseType,
	getSavedBooksRequestType,
	getSavedBooksResponseType,
	getSavedJobPostingsRequestType,
	getSavedJobPostingsResponseType,
	getSavedUsersRequestType,
	getSavedUsersResponseType,
	GetSponsorshipAllRequestType,
	GetSponsorshipAllResponseType,
	GetSponsorShipsTop10Response,
	GetTop10UserRequestType,
	GetTop10UserResponseType,
	GetTop10UsersLikeRequestType,
	GetTop10UsersLikeResponseType,
	getUserDetailRequestType,
	getUserDetailResponseType,
	GetUserEndorsmentsRequestType,
	getUserIdDataResponse,
	GetUserProfileInteractionsParams,
	GetUserProfileInteractionsResponse,
	GetUsersRequestType,
	GetUsersResponseType,
	GetWislistRequestType,
	GetWislistResponseType,
	HashtagPerModuleResponseType,
	HistoryResponseType,
	InviteUsersRequestType,
	InviteUsersResponseType,
	LiveVideosListResponseType,
	MeetingPlan,
	MyBooksResponse,
	myRelatedAccountsResponseType,
	myRelatedEntityProfilesResponseType,
	MySponsorshipsRequestType,
	MySponsorsRequestType,
	MySponsorsResponseType,
	NewsResponseType,
	NomninationsVideos,
	NotificationResponseType,
	PodcastgetallRequestType,
	QueuedDataResourceType,
	RecentlyUsedFoldersResponseType,
	RoomsRequestType,
	RoomsResponseType,
	ShortsByHashtagResourceType,
	StreamedVideosListResponseType,
	SubscribedChannelsResourceType,
	SuggestionsRequestType,
	SuggestionsResponseType,
	TrendingHashtagResourceType,
	userBusinessProfileRequestType,
	userBusinessProfileResponseType,
	UserConversationsRequestType,
	UserConversationsResponseType,
	UserModulesResponseType,
	userPersonalProfileRequestType,
	userPersonalProfileResponseType,
	VideosByChannelIdResourceType,
	VideosResourceType,
	VidoeInteractionResoruceType,
	VLanceClientsByUserIdRequest,
	VLanceMyAssetsMyProjects,
	VLanceServiceRequest,
	VTubeAuthorResourceType,
	VTubeCategory,
	VTubeCategoryDataResource,
	WalletBalanceRequestType,
	WalletBalanceResponseType,
	WALLETS_ENUM_TYPE,
	walletStatementsRequestType,
	walletStatementsResponseType,
	walletStatementsWithdrawAndFundedRequestType,
	walletWithdrawRequestRequestType,
	walletWithdrawRequestResponseType,
	WatchLaterVideosResourceType,
	WatchTrackerVideosResourceType,
} from '@/utils/types';
import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from '../customFetchBase';
import { endpoints } from './endpoints';

export const queryAPI = createApi({
	reducerPath: 'queryAPI',
	baseQuery: customFetchBase,
	tagTypes: [
		'Profile',
		'User',
		'History',
		'Notifications',
		'Rooms',
		'Chats',
		'PlanMeeting',
		'Blogs',
		'MyBlogPost',
		'Jobs',
		'Book',
		'Events',
		'Sponsorships',
		'Podcasts',
		'SimilarPodcasts',
		'Channels',
		'Videos',
		'Consultation',
		'Service',
		'Project',
		'Projects',
		'Services',
		'College',
		'Gallery',
		'Expert',
		'Shorts',
		'Webinars',
		'Wishlists',
		'Cart',
		'Wallet',
		'TimelinePosts',
		'socialPagePosts',
		'socialGroupPosts',
		'Notes',
		'folders',
		'Coupons',
	],
	endpoints: (build) => ({
		//**********General***********//
		getSubscriptionPlans: build.query<any, any>({
			query: ({ ...params }) => {
				return {
					url: endpoints.subscriptionPlan,
					method: 'GET',
					params: params,
				};
			},
		}),

		getSettings: build.query({
			query: () => ({
				url: endpoints.settings,
				method: 'GET',
			}),
		}),

		getSettingByKey: build.query({
			query: ({ key }) => ({
				url: `${endpoints.settings}/${key}`,
				method: 'GET',
				headers: {
					Accept: 'application/json',
				},
			}),
		}),

		//**********credits Details***********//
		getAllCreditsDetails: build.query<any, any>({
			query: ({ ...params }) => ({
				url: endpoints.creditsDetails,
				method: 'GET',
				params: params,
			}),
		}),

		//**********V_SEARCH***********//
		getVVSearchPostsByTypeByUser: build.query<any, any>({
			query: ({ ...params }) => {
				return {
					url: endpoints.getVVSearchPostsByTypeByUser,
					method: 'GET',
					params: params,
				};
			},
			providesTags: ['TimelinePosts'],
		}),

		getVVSearchGalleryByUserId: build.query<any, any>({
			query: (params) => {
				return {
					url: `${endpoints.VNationGetGalleryByUserId}`,
					method: 'GET',
					params,
				};
			},
			providesTags: ['Gallery'],
		}),

		getAllCredits: build.query<any, { page: number }>({
			query: ({ page }) => ({
				url: `${endpoints.getAllCredits}?page=${page}`,
				method: 'GET',
			}),
		}),

		getEarnedCreditsByUser: build.query<any, { page: number }>({
			query: ({ page }) => ({
				url: `${endpoints.getEarnedCreditsByUser}?page=${page}`,
				method: 'GET',
			}),
		}),

		getVVSearchGalleryForPageAndGroupByIdVNation: build.query<any, any>({
			query: ({ ...params }) => {
				return {
					url: endpoints.getVVSearchGalleryForPageAndGroupByIdVNation,
					method: 'GET',
					params,
				};
			},
		}),

		getVVSearchEndorsement: build.query<any, any>({
			query: (params) => {
				return {
					url: `${endpoints.getVVSearchEndorsement}`,
					method: 'GET',
					params,
				};
			},
		}),

		getVVSearchLikeShare: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.getVVSearchLikeShare,
					method: 'GET',
					params: params,
				};
			},
		}),

		getVVSearchGroupMembersByUser: build.query<any, any>({
			query: (params) => {
				return {
					url: `${endpoints.getVVSearchGroupMembersByUser}`,
					method: 'GET',
					params,
				};
			},
		}),

		getVVSearchFollowedData: build.query<any, any>({
			query: (params) => {
				return {
					url: `${endpoints.getVVSearchFollowedData}/${params.userId}`,
					method: 'GET',
					params,
				};
			},
		}),

		getVVSearchJoinedGroups: build.query<any, any>({
			query: (params) => {
				return {
					url: `${endpoints.getVVSearchJoinedGroups}/${params.userId}`,
					method: 'GET',
					params,
				};
			},
		}),

		//**********V_NATION***********//

		//followers fetch as per userId
		getVNationFollowerByUserId: build.query<any, any>({
			query: (params) => {
				return {
					url: `${endpoints.VNationGetAllFollowersByUserId}/${params.id}`,
					// url: endpoints.VNationGetAllFollowersByUserId,
					method: 'GET',
					params,
				};
			},
		}),
		//gallery fetch as per userId
		getVNationGalleryByUserId: build.query<any, any>({
			query: (params) => {
				return {
					url: `${endpoints.VNationGetGalleryByUserId}`,
					method: 'GET',
					params,
				};
			},
			providesTags: ['Gallery'],
		}),

		getVNationAllGroupMembers: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.VNationGetAllGroupMembers,
					method: 'GET',
					params: params,
				};
			},
		}),

		getVNationFollowedData: build.query<any, any>({
			query: ({ ...params }) => {
				return {
					url: endpoints.VNationGetFollowedData,
					method: 'GET',
					params,
				};
			},
		}),

		getTimezones: build.query<any, any>({
			query: () => {
				return {
					url: endpoints.getTimezones,
					method: 'GET',
				};
			},
		}),

		walletBalance: build.query<WalletBalanceResponseType, WalletBalanceRequestType>({
			query: (body) => {
				return {
					url: endpoints.walletBalance,
					method: 'GET',
					params: body,
				};
			},
		}),

		walletWithdrawRequests: build.query<walletWithdrawRequestResponseType, walletWithdrawRequestRequestType>({
			query: (body) => {
				return {
					url: endpoints.walletWithdrawRequests,
					method: 'GET',
					params: body,
				};
			},
		}),

		walletStatements: build.query<walletStatementsResponseType, walletStatementsRequestType>({
			query: (body) => {
				return {
					url: endpoints.walletStatements,
					method: 'GET',
					params: body,
				};
			},
		}),

		walletStatementsFundedAndWithdraw: build.query<any, walletStatementsWithdrawAndFundedRequestType>({
			query: (params) => {
				const mapped_params: any = {
					type: params.type,
				};
				if (params.start_date) {
					mapped_params.start_date = params.start_date;
				}
				if (params.end_date) {
					mapped_params.end_date = params.end_date;
				}
				if (params.limit) {
					mapped_params.limit = params.limit;
				}

				return {
					url: `${endpoints.walletStatementsFundedAndWithdraw}?page=${params.page}`,
					method: 'GET',
					params: mapped_params,
				};
			},
		}),

		getWalletStatements: build.query<any, any>({
			query: (body) => ({
				url: endpoints.walletStatements,
				method: 'GET',
				params: body,
				headers: {
					Accept: 'application/json',
				},
			}),
		}),

		// getMyReferrals: build.query<any, { token: string }>({
		// 	query: ({ token }) => ({
		// 		url: endpoints.myreferrals,
		// 		method: 'GET',
		// 		params: { token },
		// 	}),
		// }),
		userPersonalProfile: build.query<userPersonalProfileResponseType, userPersonalProfileRequestType>({
			query: (body) => {
				return {
					url: `${endpoints.updatePersonalProfile}/${body.id}`,
					method: 'GET',
				};
			},
		}),

		userBusinessProfile: build.query<userBusinessProfileResponseType, userBusinessProfileRequestType>({
			query: (body) => {
				return {
					url: `${endpoints.updateBusinessProfile}/${body.id}`,
					method: 'GET',
				};
			},
		}),

		myRelatedAccounts: build.query<myRelatedAccountsResponseType, any>({
			query: () => {
				return {
					url: endpoints.myRelatedAccounts,
					method: 'GET',
				};
			},
			providesTags: ['User'],
		}),

		myRelatedEntityProfiles: build.query<myRelatedEntityProfilesResponseType, any>({
			query: () => {
				return {
					url: endpoints.myRelatedProfiles,
					method: 'GET',
				};
			},
		}),

		countries: build.query<any, any>({
			query: () => {
				return {
					url: endpoints.countries,
					method: 'GET',
				};
			},
		}),

		cities: build.query<any, any>({
			query: (body) => {
				return {
					url: `${endpoints.cities}/${body.id}`,
					method: 'GET',
				};
			},
		}),

		languages: build.query<any, any>({
			query: () => {
				return {
					url: endpoints.languages,
					method: 'GET',
				};
			},
		}),

		languageLevels: build.query<any, any>({
			query: () => {
				return {
					url: endpoints.languageLevels,
					method: 'GET',
				};
			},
		}),

		languageLevelsCombine: build.query<any, any>({
			query: () => {
				return {
					url: endpoints.languageLevelsCombine,
					method: 'GET',
				};
			},
		}),

		blogPostCategories: build.query<any, any>({
			query: () => {
				return {
					url: endpoints.blogPostCategories,
					method: 'GET',
				};
			},
		}),

		getNotification: build.query<NotificationResponseType, any>({
			query: (params) => {
				return {
					url: `${endpoints.notifications}`,
					method: 'GET',
					params: params,
				};
			},
			providesTags: ['Notifications'],
		}),

		notificationCount: build.query<any, any>({
			query: () => {
				return {
					url: `${endpoints.notificationCount}`,
					method: 'GET',
				};
			},
			providesTags: ['Notifications'],
		}),
		toSeeNotification: build.query<any, any>({
			query: (params) => {
				return {
					url: `${endpoints.toSeeNotification}`,
					method: 'GET',
					params,
				};
			},
			providesTags: ['Notifications'],
		}),

		getHistory: build.query<HistoryResponseType, any>({
			query: (params) => {
				return {
					url: `${endpoints.history}`,
					method: 'GET',
					params: params,
				};
			},
			providesTags: ['History'],
		}),

		businessTypes: build.query<any, any>({
			query: () => endpoints.businessTypes,
		}),
		folders: build.query<FolderRawResponseType, FolderRequestType>({
			query: (params) => {
				return {
					url: endpoints.folders,
					method: 'GET',
					params: params,
				};
			},
			providesTags: ['folders'],
		}),
		getRecentlyUsedFolders: build.query<RecentlyUsedFoldersResponseType, null>({
			query: () => {
				return {
					url: endpoints.myStorageRecentlyUsedFolders,
					method: 'GET',
				};
			},
		}),
		folderDetails: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.folders}/${id}`,
					method: 'GET',
				};
			},
		}),
		folderFiles: build.query<FolderFileResponseType, FolderFileRequestType>({
			query: ({ id, ...rest }) => {
				return {
					url: `${endpoints.folderFiles}/${id}`,
					method: 'GET',
					params: rest,
				};
			},
		}),
		foldersFiles: build.query<any, any>({
			query: (params) => {
				return {
					url: `${endpoints.foldersFiles}/type`,
					method: 'GET',
					params: params,
				};
			},
			providesTags: ['folders'],
		}),
		VlanceCategories: build.query<{ data: VTubeCategory[] }, any>({
			query: () => {
				return {
					url: endpoints.generalVLanceCategories,
					method: 'GET',
				};
			},
		}),
		VlanceGallery: build.query<any, any>({
			query: (params) => {
				return {
					url: `${endpoints.gallery}`,
					method: 'GET',
					params: params,
				};
			},
			providesTags: ['Gallery'],
		}),

		collectiveConnections: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.collectiveConnections,
					method: 'GET',
					params: params,
				};
			},
		}),

		collectiveFollowers: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.collectiveFollowers,
					method: 'GET',
					params: params,
				};
			},
		}),

		collectiveMembers: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.collectiveMembers,
					method: 'GET',
					params: params,
				};
			},
		}),

		collectiveSubscribers: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.collectiveSubscribers,
					method: 'GET',
					params: params,
				};
			},
		}),

		collectiveJobs: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.collectivejobs,
					method: 'GET',
					params: params,
				};
			},
		}),

		collectiveServices: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.collectiveServices,
					method: 'GET',
					params: params,
				};
			},
		}),

		collectiveClients: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.collectiveClients,
					method: 'GET',
					params: params,
				};
			},
		}),

		generalUsers: build.query<GeneralUserResponseType, GeneralUserRequestType>({
			query: (body) => {
				return {
					url: endpoints.generalUsers,
					method: 'GET',
					params: body,
				};
			},
		}),
		getNotes: build.query<GetNotesResponseType, any>({
			query: (params) => {
				return {
					url: `${endpoints.notes}`,
					method: 'GET',
					params: params,
				};
			},
		}),
		getNews: build.query<NewsResponseType, any>({
			query: (params) => {
				return { url: `${endpoints.news}`, method: 'GET', params: params };
			},
		}),

		jobPostCategories: build.query<any, any>({
			query: () => {
				return {
					url: endpoints.jobPostCategories,
					method: 'GET',
				};
			},
		}),

		getAdSpot: build.query<AdsResponseType, void>({
			query: () => {
				return { url: endpoints.adspot, method: 'GET' };
			},
		}),

		getAdRequest: build.query<any, any>({
			query: (body) => {
				return { url: endpoints.adRequest, method: 'GET', params: body };
			},
		}),

		getAdManageData: build.query<any, any>({
			query: (body) => {
				return { url: endpoints.adManage, method: 'GET', params: body };
			},
		}),

		getUserData: build.query<getUserIdDataResponse, any>({
			query: (body) => {
				return { url: endpoints.getUserData, method: 'GET' };
			},
			providesTags: ['Profile'],
		}),

		storageSummary: build.query<any, any>({
			query: (body) => {
				return { url: endpoints.myStorageSummary, method: 'GET', params: body };
			},
		}),

		myStorageRecentlyUsedFiles: build.query<any, any>({
			query: (body) => {
				return {
					url: endpoints.myStorageRecentlyUsedFiles,
					method: 'GET',
					params: body,
				};
			},
		}),
		serviceCategories: build.query<any, void>({
			query: () => {
				return { url: endpoints.serviceCategories, method: 'GET' };
			},
		}),
		ProjectCategories: build.query<any, any>({
			query: () => {
				return {
					url: endpoints.projectCategories,
					method: 'GET',
				};
			},
		}),

		rooms: build.query<RoomsResponseType, RoomsRequestType>({
			query: (params) => {
				return { url: endpoints.roomConversation, method: 'GET', params };
			},
			providesTags: ['Rooms'],
		}),

		userConversations: build.query<UserConversationsResponseType, UserConversationsRequestType>({
			query: (params) => {
				return { url: endpoints.userConversations, method: 'GET', params };
			},
			providesTags: ['Chats'],
		}),

		getOrCreateConversation: build.query<any, { user: number }>({
			query: ({ user }) => {
				return {
					url: `${endpoints.getOrCreateConversation}/${user}`,
					method: 'GET',
				};
			},
			providesTags: ['Chats'],
		}),

		conversation: build.query<any, any>({
			query: (id) => {
				return { url: `${endpoints.conversation}/${id}`, method: 'GET' };
			},
			providesTags: ['Chats'],
		}),
		conversationMessages: build.query<any, any>({
			query: (params) => {
				return {
					url: `${endpoints.conversationMessages}/${params.id}`,
					method: 'GET',
					params: params,
				};
			},
			providesTags: ['Chats'],
		}),
		conversationAttachments: build.query<any, any>({
			query: (params) => {
				const { id, ...restParams } = params;
				return {
					url: `${endpoints.conversationAttachments}/${params.id}`,
					method: 'GET',
					params: restParams,
				};
			},
			providesTags: ['Chats'],
		}),
		conversationPrivateNotes: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.conversationPrivateNotes}/${id}`,
					method: 'GET',
				};
			},
		}),
		PlanById: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.Plan}/${id}`,
					method: 'GET',
				};
			},
		}),
		deletePlanById: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.Plan}/${id}`,
					method: 'DELETE',
				};
			},
		}),

		getAdSlots: build.query<any, any>({
			query: (id) => {
				return { url: `${endpoints.adSlots}/${id}`, method: 'GET' };
			},
		}),

		// getUserDetail: build.query<
		//   getUserDetailResponseType,
		//   getUserDetailRequestType
		// >({
		//   query: () => {
		//     return { url: endpoints.userDetails, method: 'GET' };
		//   },
		// }),

		roomConversation: build.query<any, any>({
			query: (id) => {
				return { url: `${endpoints.roomConversation}/${id}`, method: 'GET' };
			},
			providesTags: ['Rooms'],
		}),

		roomConversationMessages: build.query<any, any>({
			query: (params) => {
				return {
					url: `${endpoints.roomConversationMessages}/${params.id}`,
					method: 'GET',
					params: params,
				};
			},
			providesTags: ['Rooms'],
		}),

		roomConversationAttachments: build.query<any, any>({
			query: (params) => {
				return {
					url: `${endpoints.roomConversationAttachments}/${params.id}`,
					method: 'GET',
					params: params,
				};
			},
			providesTags: ['Rooms'],
		}),

		PlanMeeting: build.query<MeetingPlan, any>({
			query: (params) => {
				return { url: endpoints.Plan, method: 'GET', params };
			},
			providesTags: ['PlanMeeting'],
		}),

		roomPrivateNotes: build.query<any, any>({
			query: (id) => {
				return { url: `${endpoints.roomPrivateNotes}/${id}`, method: 'GET' };
			},
		}),

		getUserDetail: build.query<getUserDetailResponseType, getUserDetailRequestType>({
			query: (params) => {
				return { url: endpoints.userDetails, method: 'GET', params };
			},
			providesTags: ['User'],
		}),

		inviteUser: build.query<InviteUsersRequestType, InviteUsersResponseType>({
			query: (params) => {
				return {
					url: endpoints.inviteUser,

					method: 'GET',

					params: params,
				};
			},
		}),

		getPersonalOrEntityUsers: build.query<getAllUsersResponseType, getAllUsersRequestType>({
			query: (params) => {
				return {
					url: endpoints.getPersonalOrEntityUsers,
					method: 'GET',
					params: params,
				};
			},
		}),

		getConnectedUsersIds: build.query<any, any>({
			query: ({ id, ...params }) => {
				return {
					url: `${endpoints.getConnectedUsersIds}/${id}`,
					method: 'GET',
					params,
				};
			},
		}),

		// getConnectedUsers: build.query<
		//   getConnectedUsersResponseType,
		//   getConnectedUsersRequestType
		// >({
		getConnectedUsers: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.getConnectedUsers,
					method: 'GET',
					params: params,
				};
			},
			providesTags: ['User'],
		}),
		getUsersFullDetails: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.getUsersFullDetails,

					method: 'GET',

					params: params,
				};
			},
		}),

		getAllCustomers: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.getBookCustomers,
					method: 'GET',
					params: params,
				};
			},
		}),

		getAllCustomersByBook: build.query<any, any>({
			query: ({ book_id, ...rest }) => {
				return {
					url: `${endpoints.getCustomersByBook}/${book_id}`,
					method: 'GET',
					params: rest,
				};
			},
		}),
		getAllCustomersByConsultation: build.query<any, any>({
			query: ({ consultation_id, ...rest }) => {
				return {
					url: `${endpoints.getCustomersByConsultation}/${consultation_id}/customers`,
					method: 'GET',
					params: rest,
				};
			},
		}),

		getUsersCount: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.getUsersCount}/${id}`,

					method: 'GET',
				};
			},
		}),

		getAuthenticatedUserDetails: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.getAuthenticatedUserDetails,
					method: 'GET',
					params,
				};
			},
			providesTags: ['User'],
		}),
		getAuthUserbirthday: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.getAuthUserbirthday,
					method: 'GET',
					params: params,
				};
			},
		}),
		getPersonalBirthdays: build.query<BirthdaysResponseType, BirthdaysRequestType>({
			query: (params) => {
				return {
					url: endpoints.getPersonalBirthdays,
					method: 'GET',
					params: params,
				};
			},
			providesTags: ['User'],
		}),
		getEntityAnniversaries: build.query<AnniversariesResponseType, AnniversariesRequestType>({
			query: (params) => {
				return {
					url: endpoints.getEntityAnniversaries,
					method: 'GET',
					params: params,
				};
			},
			providesTags: ['User'],
		}),
		getVEventsMyProfileCustomers: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.vEventsMyCustomers,
					method: 'GET',
					params: params,
				};
			},
		}),
		getSuggestedUsers: build.query<SuggestionsResponseType, SuggestionsRequestType>({
			query: (params) => {
				return {
					url: endpoints.getSuggestedUsers,
					method: 'GET',
					params: params,
				};
			},
			providesTags: ['User'],
		}),
		// getConnectionRequests: build.query<
		//   ConnectionRequestsResponseType,
		//   ConnectionRequestsRequestType
		// >({
		getConnectionRequests: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.getConnectionRequests,
					method: 'GET',
					params: params,
				};
			},
			providesTags: ['User'],
		}),
		getUserDetails: build.query<any, any>({
			query: (body) => {
				return {
					url: endpoints.user_details,
					method: 'POST',
					params: {
						...body,
						user_id: body.user_id,
						module_enum: body.module_enum || 'General',
						asset_enum: body.asset_enum,
					},
				};
			},
		}),
		getRoomCallDetails: build.query<any, any>({
			query: (callId) => {
				return {
					url: `${endpoints.roomChannel}/${callId}`,
					method: 'GET',
				};
			},
		}),

		getCallDetails: build.query<any, any>({
			query: (callId) => {
				return {
					url: `${endpoints.privateConnection}/call-details/${callId}`,
					method: 'GET',
				};
			},
		}),

		channelCategories: build.query<any, any>({
			query: () => {
				return { url: endpoints.channelCategories, method: 'GET' };
			},
		}),
		channelPlaylistsByChannelId: build.query<any, any>({
			query: ({ id, ...params }) => {
				return {
					url: `${endpoints.getPlaylistsUsingChannelId}/${id}`,
					method: 'GET',
					params: params,
				};
			},
		}),
		channelVideosByChannelId: build.query<any, any>({
			query: ({ id, type = 'video' }) => {
				return {
					url: `${endpoints.getVidoesUsingChannelId}/${id}?type=${type}`,
					method: 'GET',
				};
			},
		}),
		channelShortsByChannelId: build.query<any, any>({
			query: ({ id, type = 'short' }) => {
				return {
					url: `${endpoints.getVidoesUsingChannelId}/${id}?type=${type}`,
					method: 'GET',
				};
			},
		}),
		roomParticipants: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.roomsParticipants}/${id}`,
					method: 'GET',
				};
			},
			providesTags: ['Rooms'],
		}),

		getAllEndorsments: build.query<GetAllEndorsmentsResponseType, GetAllEndorsmentsRequestType>({
			query: (params) => {
				return {
					url: endpoints.getAllEndorsments,
					method: 'GET',
					params: params,
				};
			},
			providesTags: ['Podcasts', 'Sponsorships'],
		}),

		getAllEndorsmentById: build.query<GetAllEndorsmentsResponseType, EndorsmentByIdRequestType>({
			query: ({ userId }) => {
				return {
					url: `${endpoints.getAllEndorsmentById}/${userId}`,
					method: 'GET',
				};
			},
		}),
		getAllEndorsmentsForUser: build.query<GetAllEndorsmentsResponseType, GetUserEndorsmentsRequestType>({
			query: (params) => {
				return {
					url: endpoints.getAllEndorsmentsForUser,
					method: 'GET',
					params: params,
				};
			},
		}),
		getAllProfileInteractions: build.query<GetUserProfileInteractionsResponse, GetUserProfileInteractionsParams>({
			query: (params) => {
				return {
					url: endpoints.getAllProfileInteractions,
					method: 'GET',
					params: params,
				};
			},
		}),
		getTop10UsersInteractions: build.query<GetTop10UsersLikeResponseType, GetTop10UsersLikeRequestType>({
			query: (params) => {
				return {
					url: endpoints.getTop10UsersInteractions,
					method: 'GET',
					params: params,
				};
			},
		}),
		getAuthUserProfileInteractions: build.query<
			GetUserProfileInteractionsResponse,
			GetAuthUserProfileInteractionsRequestType
		>({
			query: (params) => {
				return {
					url: endpoints.getAuthUserProfileInteractions,
					method: 'GET',
					params: params,
				};
			},
		}),
		getTop10VlanceServices: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.getTop10VlanceServices,
					method: 'GET',
					params: params,
				};
			},
		}),
		getAuthUserProfileInteractionsById: build.query<any, EndorsmentByIdRequestType>({
			query: ({ userId }) => {
				return {
					url: `${endpoints.getAuthUserProfileInteractionsById}/${userId}`,
					method: 'GET',
				};
			},
		}),

		getDownloadedBooks: build.query<MyBooksResponse, any>({
			query: (params) => {
				return {
					url: endpoints.myBooks,
					method: 'GET',
					params: params,
				};
			},
		}),

		allConversations: build.query<any, any>({
			query: (params) => {
				return { url: endpoints.allConversation, method: 'GET', params };
			},
			providesTags: ['Chats', 'Rooms'],
		}),

		getBlogCategory: build.query<any, any>({
			query: () => {
				return {
					url: endpoints.blogCategory,
					method: 'GET',
				};
			},
		}),

		getBookCategory: build.query<any, any>({
			query: () => {
				return {
					url: endpoints.bookCategory,
					method: 'GET',
				};
			},
		}),

		getTopAuthors: build.query<any, any>({
			query: () => {
				return {
					url: endpoints.top10authors,
					method: 'GET',
				};
			},
		}),

		getTopBookAuthors: build.query<any, any>({
			query: () => {
				return {
					url: endpoints.top10bookAuthors,
					method: 'GET',
				};
			},
		}),

		getTopAuthorsByCountry: build.query<any, { id: number }>({
			query: ({ id }) => {
				return {
					url: `${endpoints.top10authors}/${id}`,
					method: 'GET',
				};
			},
		}),

		getTopBlog: build.query<any, any>({
			query: ({ all_interactions }) => {
				return {
					url: `${endpoints.top10posts}?all_interactions= ${all_interactions}`,
					method: 'GET',
				};
			},
		}),

		getMyBlogPosts: build.query<any, any>({
			query: (params) => {
				return {
					url: `${endpoints.MyBlogPosts}`,
					method: 'GET',
					params,
				};
			},
			providesTags: ['Blogs'],
		}),

		getMyBooks: build.query<getSavedBooksResponseType, getSavedBooksRequestType>({
			query: (params) => {
				return {
					url: `${endpoints.myBooks}`,
					method: 'GET',
					params,
				};
			},
		}),

		getMyEventsMyAssets: build.query<any, any>({
			query: (params) => {
				return {
					url: `${endpoints.getMyEvents}`,
					method: 'GET',
					params,
				};
			},
			providesTags: ['Events'],
		}),
		getMyBlogPostsById: build.query<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.blogPost}/${id}`,
					method: 'GET',
				};
			},
		}),
		getChannelsById: build.query<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.getChannelInfoByChannelId}/${id}`,
					method: 'GET',
				};
			},
		}),
		getPlaylistByUserId: build.query<any, { user_id: string }>({
			query: ({ user_id }) => {
				return {
					url: `${endpoints.showPlaylistUsingPlaylistId}/${user_id}`,
					method: 'GET',
				};
			},
		}),

		getMyEventsById: build.query<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.getMyEventsAssets}/${id}`,
					method: 'GET',
				};
			},
		}),
		getTopLikedBlog: build.query<any, any>({
			query: ({ all_interactions }) => {
				return {
					url: `${endpoints.top10posts}?all_interactions= ${all_interactions}&interaction_type_enum=likes`,
					method: 'GET',
				};
			},
		}),

		getTopSharedBlog: build.query<any, any>({
			query: ({ all_interactions }) => {
				return {
					url: `${endpoints.top10posts}?all_interactions= ${all_interactions}&interaction_type_enum=shares`,
					method: 'GET',
				};
			},
		}),

		getTopCommentedBlog: build.query<any, any>({
			query: ({ all_interactions }) => {
				return {
					url: `${endpoints.top10posts}?all_interactions= ${all_interactions}&interaction_type_enum=comments`,
					method: 'GET',
				};
			},
		}),

		getFeaturedAuthors: build.query<any, any>({
			query: () => {
				return {
					url: endpoints.featuredAuthors,
					method: 'GET',
				};
			},
		}),

		getFeaturedPost: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.featuredposts,
					method: 'GET',
					params,
				};
			},
		}),

		getLatestPost: build.query<any, { get_all: number; limit: number; category_id: number }>({
			query: ({ get_all, limit, category_id }) => {
				return {
					url: `${endpoints.latestPosts}?limit=${limit}&get_all=${get_all}&category_id=${category_id}`,
					method: 'GET',
				};
			},
		}),

		getHandpickedPerCategory: build.query<any, { category_id: number; sub_category_id: number }>({
			query: ({ category_id, sub_category_id }) => {
				return {
					url: `${endpoints.handPickedPerCategory}?category_id=${category_id}&sub_category_id=${sub_category_id}&limit=3`,
					method: 'GET',
				};
			},
		}),

		getAllBlog: build.query<AllBlogsResponseType, AllBlogsRequestType>({
			query: (params) => {
				return {
					url: endpoints.allBlogPosts,
					method: 'GET',
					params,
				};
			},
		}),

		getBlogById: build.query<any, { id: number }>({
			query: ({ id }) => {
				return {
					url: `${endpoints.blogPost}/${id}`,
					method: 'GET',
				};
			},
			providesTags: ['Blogs'],
		}),

		getBookById: build.query<any, { id: number }>({
			query: ({ id }) => {
				return {
					url: `${endpoints.book}/${id}`,
					method: 'GET',
				};
			},
			providesTags: ['Book'],
		}),

		getSimilarBlogSameAuthor: build.query<any, { id: number }>({
			query: ({ id }) => {
				return {
					url: `${endpoints.blogPost}/${id}/similar-same`,
					method: 'GET',
				};
			},
		}),

		getSimilarBookSameAuthor: build.query<any, { id: number }>({
			query: ({ id }) => {
				return {
					url: `${endpoints.book}/${id}/similar-same`,
					method: 'GET',
				};
			},
		}),

		getSimilarBlogOtherAuthor: build.query<any, { id: number }>({
			query: ({ id }) => {
				return {
					url: `${endpoints.blogPost}/${id}/similar-other`,
					method: 'GET',
				};
			},
			providesTags: ['Blogs'],
		}),

		getSimilarBookOtherAuthor: build.query<any, { id: number }>({
			query: ({ id }) => {
				return {
					url: `${endpoints.book}/${id}/similar-other`,
					method: 'GET',
				};
			},
		}),

		getSimilarConsultationOtherAuthor: build.query<any, { id: number }>({
			query: ({ id }) => {
				return {
					url: `${endpoints.getSimilarConsultationsOtherAuthors}/${id}`,
					method: 'GET',
				};
			},
		}),

		getSimilarConsultationSameAuthor: build.query<any, { id: number }>({
			query: ({ id }) => {
				return {
					url: `${endpoints.getSimilarConsultationsSameAuthors}/${id}`,
					method: 'GET',
				};
			},
		}),

		getAllBlogPostInteractions: build.query<
			any,
			{
				blog_post_id: number;
				type: string;
				details?: string;
				filters?: string[];
			}
		>({
			query: (params) => {
				return {
					url: endpoints.getBlogPostinterations,
					method: 'GET',
					params: params,
				};
			},
			providesTags: ['Blogs'],
		}),

		getAllBookInteractions: build.query<
			any,
			{
				book_id: number;
				type: 'like' | 'share' | 'comment' | 'review';
				details?: string;
				filters?: string[];
			}
		>({
			query: (params) => {
				return {
					url: endpoints.getBookInteractions,
					method: 'GET',
					params: params,
				};
			},
			providesTags: ['Book'],
		}),
		getAllConsultationInteractions: build.query<
			any,
			{
				consultation_id: number;
				type: 'like' | 'share' | 'comment' | 'review';
				details?: string;
				filters?: string[];
			}
		>({
			query: (params) => {
				return {
					url: endpoints.getConsultationInteractions,
					method: 'GET',
					params: params,
				};
			},
			providesTags: ['Consultation'],
		}),

		socialCategories: build.query<any, any>({
			query: () => {
				return { url: endpoints.socialCategories, method: 'GET' };
			},
		}),

		getAllModuleDetails: build.query<GetAllUserProfilesResponseType, GetAllUserProfileRequestType>({
			query: (id) => {
				return {
					url: `${endpoints.userAllModuleDetails}/${id.id}`,
					method: 'GET',
					params: {
						id: id,
					},
				};
			},
		}),

		getTop10UserDetails: build.query<GetTop10UserResponseType, GetTop10UserRequestType>({
			query: (body) => {
				return {
					url: endpoints.top10Users,
					method: 'GET',
					params: {
						module_enum: body.module_enum,
						asset_enum: body.asset_enum,
						profile_type_enum: body.profile_type_enum,
					},
				};
			},
		}),

		getTop10Books: build.query<any, any>({
			query: (body) => {
				return {
					url: endpoints.top10Books,
					method: 'GET',
					params: {
						all_interactions: body.all_interactions,
					},
				};
			},
		}),

		myJobs: build.query<getmyJobsResponseType, getmyJobsRequestType>({
			query: (body) => {
				return {
					url: endpoints.myJobs,
					method: 'GET',
					params: body,
				};
			},
			providesTags: ['Jobs'],
		}),

		publishedJobs: build.query<GetPublishedJobsResponseType, GetPublishedJobsRequestType>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.publishedJobs}/${id}`,
					method: 'GET',
					params: body,
				};
			},
			providesTags: ['Jobs'],
		}),

		Myreferrals: build.query<any, any>({
			query: () => {
				return {
					url: endpoints.Myreferrals,
					method: 'GET',
				};
			},
		}),

		getDepartments: build.query<any, any>({
			query: () => {
				return {
					url: endpoints.departments,
					method: 'GET',
				};
			},
		}),

		allJobs: build.query<getAllJobPostingsResponseType, getAllJobPostingsRequestType>({
			query: (body) => {
				return {
					url: endpoints.allJobs,
					method: 'GET',
					params: body,
				};
			},
			providesTags: ['Jobs'],
		}),

		allPodcasts: build.query<getAllposdcastsResponseType, PodcastgetallRequestType>({
			query: (params) => {
				return {
					url: endpoints.getallpodcast,
					method: 'GET',
					params,
				};
			},
			providesTags: ['Podcasts'],
		}),

		allBooks: build.query<getAllBooksResponseType, getAllBooksRequestType>({
			query: (body) => {
				return {
					url: endpoints.allBooks,
					method: 'GET',
					params: body,
				};
			},
			providesTags: ['Book'],
		}),

		getDepartmentsRoleLevel: build.query<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.departments}/${id}/role-levels`,
					method: 'GET',
				};
			},
		}),

		getMyApplications: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.getMyApplications,
					method: 'GET',
					params,
				};
			},
			providesTags: ['Jobs'],
		}),

		getJobPosting: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.getJobPosting}/${id}`,
					method: 'GET',
				};
			},
			providesTags: ['Jobs'],
		}),

		getReports: build.query<any, any>({
			query: (body) => {
				return {
					url: endpoints.report,
					method: 'GET',
					params: body,
				};
			},
		}),

		bookAuthors: build.query<getBookAuthorResponseType, getBookAuthorsRequestType>({
			query: (body) => {
				return {
					url: endpoints.bookAuthors,
					method: 'GET',
					params: body,
				};
			},
		}),

		jobsEmployers: build.query<getJobEmployersResponseType, getJobEmployersRequestType>({
			query: (body) => {
				return {
					url: endpoints.jobsEmployers,
					method: 'GET',
					params: body,
				};
			},
			providesTags: ['Jobs'],
		}),

		candidates: build.query<getCandidatesResponseType, getCandidatesRequestType>({
			query: (body) => {
				return {
					url: endpoints.candidates,
					method: 'GET',
					params: body,
				};
			},
		}),

		getSavedUsersByModuleAssetName: build.mutation<getSavedUsersResponseType, getSavedUsersRequestType>({
			query: (body) => {
				return {
					url: endpoints.getSavedUsersByModuleAssetName,
					method: 'POST',
					params: body,
				};
			},
		}),

		savedJobPostings: build.query<getSavedJobPostingsResponseType, getSavedJobPostingsRequestType>({
			query: (body) => {
				return {
					url: endpoints.savedJobPostings,
					method: 'GET',
					params: body,
				};
			},
			providesTags: ['Jobs'],
		}),

		savedBooks: build.query<getSavedBooksResponseType, getSavedBooksRequestType>({
			query: (body) => {
				return {
					url: endpoints.myBooks,
					method: 'GET',
					params: {
						...body,
						status: 'saved',
					},
				};
			},
			providesTags: ['Book'],
		}),

		vJobEmployeeCandidates: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.vJobEmployeeCandidates,
					method: 'POST',
					params,
				};
			},
			providesTags: ['Jobs'],
		}),
		vEventsDownloads: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.vEventsDownloads,
					method: 'GET',
					params,
				};
			},
		}),
		getJobPostingInteractions: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.getJobPostingInteractions,
					method: 'GET',
					params: params,
				};
			},
			providesTags: ['Jobs'],
		}),

		getEntityAnniversary: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.entityAnniversaries,
					method: 'GET',
					params: params,
				};
			},
		}),
		getSimilarJobPostsBySameAuthor: build.query<any, any>({
			query: ({ JobPostId }) => {
				return {
					url: `vjobs/${JobPostId}/similar-same`,
					method: 'GET',
				};
			},
			providesTags: ['Jobs'],
		}),

		getSimilarJobPostsByOtherAuthors: build.query<any, any>({
			query: ({ JobPostId }) => {
				return {
					url: `vjobs/${JobPostId}/similar-other`,
					method: 'GET',
				};
			},
			providesTags: ['Jobs'],
		}),
		getMyProjects: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.getMyProjects,
					method: 'GET',
					params,
				};
			},
		}),
		getMyEvents: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.getMyEvents,
					method: 'GET',
					params,
				};
			},
		}),
		messagesShow: build.query<any, any>({
			query: ({ message }) => {
				return {
					url: `${endpoints.messagesShow}/${message}`,
					method: 'GET',
				};
			},
		}),
		roomMessagesShow: build.query<any, any>({
			query: ({ message }) => {
				return {
					url: `${endpoints.room_messagesShow}/${message}`,
					method: 'GET',
				};
			},
		}),
		allRoomMessageEmojis: build.query<any, any>({
			query: ({ message_id, emoji, sort_order }) => {
				let url = `${endpoints.allRoomMessageEmojis}/${message_id}`;

				const queryParams = new URLSearchParams();
				if (emoji !== null && emoji !== undefined) {
					queryParams.append('emoji', emoji);
				}
				if (sort_order !== null && sort_order !== undefined) {
					queryParams.append('sort_order', sort_order);
				}

				if (queryParams.toString()) {
					url += `?${queryParams.toString()}`;
				}

				return {
					url,
					method: 'GET',
				};
			},
		}),
		userModules: build.query<UserModulesResponseType, { userId: string }>({
			query: ({ userId }) => {
				return {
					url: `${endpoints.userModules}/${userId}/modules`,
					method: 'GET',
				};
			},
			providesTags: ['Profile'],
		}),

		blogAuthors: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.vblogAuthors,
					method: 'GET',
					params,
				};
			},
		}),
		getUpdateProfileInteraction: build.mutation<any, any>({
			query: (body) => {
				return {
					url: `${endpoints.updateProfileInteraction}/${body.id}`,
					method: 'GET',
					params: body,
				};
			},
		}),
		showJobPostingInteraction: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.showJobPostingInteraction}/${id}/show`,
					method: 'GET',
				};
			},
		}),
		showBlogPostInteraction: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.showBlogPostInteraction}/${id}/show`,
					method: 'GET',
				};
			},
		}),
		showBookInteraction: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.showBookInteraction}/${id}`,
					method: 'GET',
				};
			},
			providesTags: ['Book'],
		}),
		showConsultationInteraction: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.showConsultationInteraction}/${id}`,
					method: 'GET',
				};
			},
			providesTags: ['Consultation'],
		}),
		getUserBlogPostById: build.query<any, any>({
			query: ({ id, ...params }) => {
				return {
					url: `${endpoints.getUserBlogPostById}/${id}`,
					method: 'GET',
					params: params,
				};
			},
		}),
		getBusinessData: build.query<any, any>({
			query: (userId) => {
				return {
					url: endpoints.getBusinessData,
					method: 'GET',
				};
			},
		}),

		savedSponsorShip: build.query<any, MySponsorshipsRequestType>({
			query: (body) => {
				return {
					url: endpoints.savedSponsorShip,
					method: 'GET',
					params: body,
				};
			},
			providesTags: ['Sponsorships'],
		}),

		savedConsultations: build.query<any, getAllConsultationsRequestType>({
			query: (body) => {
				return {
					url: endpoints.savedConsultations,
					method: 'GET',
					params: body,
				};
			},
		}),

		getSponsorship: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.getSponsorship}/${id}`,
					method: 'GET',
				};
			},
			providesTags: ['Sponsorships'],
		}),

		getCourse: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.getCourse}/${id}`,
					method: 'GET',
				};
			},
			providesTags: ['College'],
		}),
		postCourseEnroll: build.query<any, any>({
			query: ({ id, body }) => {
				return {
					url: `${endpoints.getCourse}/${id}/enroll`,
					method: 'POST',
					body,
				};
			},
		}),

		getLessonById: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.getLessonById}/${id}`,
					method: 'GET',
				};
			},
		}),

		putUpdateLessonTracker: build.query<any, any>({
			query: ({ id, ...body }) => {
				return {
					url: `${endpoints.putUpdateLessonTracker}/${id}`,
					method: 'PUT',
					body,
				};
			},
		}),
		getQuizQuestions: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.getQuizQuestions}/${id}`,
					method: 'GET',
				};
			},
		}),
		getExamQuestions: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.getExamQuestions}/${id}`,
					method: 'GET',
				};
			},
		}),
		getSponsorshipInteractions: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.getSponsorshipInteractions,
					method: 'GET',
					params: params,
				};
			},
			providesTags: ['Sponsorships'],
		}),

		getCourseInteractions: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.getCourseInteractions,
					method: 'GET',
					params: params,
				};
			},
			providesTags: ['College'],
		}),

		getUnitLessonInteractions: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.getUnitCourseInteractions,
					method: 'GET',
					params: params,
				};
			},
			providesTags: ['College'],
		}),

		getPerformanceData: build.query<any, any>({
			query: ({ id, ...params }) => {
				return {
					url: `${endpoints.getPerformanceData}/${id}`,
					method: 'GET',
					params: params,
				};
			},
			providesTags: ['College'],
		}),

		getGraduationBookData: build.query<any, any>({
			query: ({ id, ...params }) => {
				return {
					url: `${endpoints.getGraduationBookData}/${id}`,
					method: 'GET',
					params: params,
				};
			},
			providesTags: ['College'],
		}),

		showSponsorshipInteraction: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.showSponsorshipInteraction}/${id}/show`,
					method: 'GET',
				};
			},
			providesTags: ['Sponsorships'],
		}),

		showCourseInteraction: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.courseInteraction}/${id}`,
					method: 'GET',
				};
			},
		}),

		showUnitLessonInteraction: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.unitLessonInteraction}/${id}`,
					method: 'GET',
				};
			},
		}),

		getBuzzSponsorships: build.query<GetSponsorshipAllResponseType, GetSponsorshipAllRequestType>({
			query: (params) => {
				return {
					url: endpoints.getBuzzSponsorships,
					method: 'GET',
					params,
				};
			},
			providesTags: ['Sponsorships'],
		}),
		getCourses: build.query<GetSponsorshipAllResponseType, GetSponsorshipAllRequestType>({
			query: (params) => {
				return {
					url: endpoints.getCourses,
					method: 'GET',
					params,
				};
			},
			providesTags: ['Sponsorships'],
		}),
		getBuzzAuthors: build.query<GetUsersResponseType, GetUsersRequestType>({
			query: (params) => {
				return {
					url: endpoints.getBuzzAuthors,
					method: 'GET',
					params,
				};
			},
			providesTags: ['Sponsorships'],
		}),
		getVInstructors: build.query<GetUsersResponseType, GetUsersRequestType>({
			query: (params) => {
				return {
					url: endpoints.vInstructors,
					method: 'GET',
					params,
				};
			},
			providesTags: ['Sponsorships'],
		}),
		getMySponsorships: build.query<MySponsorsResponseType, MySponsorsRequestType>({
			query: (params) => {
				return {
					url: endpoints.getMySponsorships,
					method: 'GET',
					params,
				};
			},
			providesTags: ['Sponsorships'],
		}),

		getSponsorShipById: build.query<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.getSponsorShipById}/${id}`,
					method: 'GET',
				};
			},
			providesTags: ['Sponsorships'],
		}),
		getAllEvents: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.getAllEvents,
					method: 'GET',
					params,
				};
			},
		}),
		getOrganizers: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.getOrganizers,
					method: 'GET',
					params: params,
				};
			},
		}),
		getSavedEvent: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.getSavedEvent,
					method: 'GET',
					params,
				};
			},
		}),
		eventCategories: build.query<any, any>({
			query: () => {
				return {
					url: endpoints.eventCategories,
					method: 'GET',
				};
			},
		}),

		getSponsorShipsTop10Authors: build.query<
			{
				data: ConnectedUserResource[];
			},
			any
		>({
			query: () => {
				return {
					url: endpoints.getSponsorShipsTop10Authors,
					method: 'GET',
				};
			},
		}),

		getTop10Instructors: build.query<
			{
				data: ConnectedUserResource[];
			},
			any
		>({
			query: () => {
				return {
					url: endpoints.getTop10Instructors,
					method: 'GET',
				};
			},
		}),

		getConsultationTop10Authors: build.query<any, any>({
			query: () => {
				return {
					url: endpoints.getConsultationTop10Authors,
					method: 'GET',
				};
			},
		}),

		getSponsorShipsTop10: build.query<GetSponsorShipsTop10Response, any>({
			query: (params) => {
				return {
					url: endpoints.getSponsorShipsTop10,
					method: 'GET',
					params,
				};
			},
		}),

		getCoursesTop10: build.query<GetCoursesTop10Response, any>({
			query: (params) => {
				return {
					url: endpoints.getCoursesTop10,
					method: 'GET',
					params,
				};
			},
		}),

		getServicesTop10: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.getServicesTop10,
					method: 'GET',
					params,
				};
			},
		}),

		getLancersTop10: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.getLancersTop10,
					method: 'GET',
					params,
				};
			},
		}),

		getConsultationTop10: build.query<GetConsultationTop10Response, any>({
			query: (params) => {
				return {
					url: endpoints.getConsultationTop10,
					method: 'GET',
					params,
				};
			},
		}),

		getSponsorShipsCategories: build.query<any, any>({
			query: () => {
				return {
					url: endpoints.getSponsorShipsCategories,
					method: 'GET',
				};
			},
		}),

		getCollegeCategories: build.query<any, any>({
			query: () => {
				return {
					url: endpoints.getCollegeCategories,
					method: 'GET',
				};
			},
		}),

		getLancerCategories: build.query<any, any>({
			query: () => {
				return {
					url: endpoints.lancerCategories,
					method: 'GET',
				};
			},
		}),

		getSponsorshipMyCustomers: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.getSponsorshipMyCustomers,
					method: 'GET',
					params,
				};
			},
			providesTags: ['Sponsorships'],
		}),

		getCollegeMyCustomers: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.getCollegeMyCustomers,
					method: 'GET',
					params,
				};
			},
		}),

		getSponsorshipCustomersById: build.query<any, any>({
			query: ({ id, ...params }) => {
				return {
					url: `${endpoints.getSponsorshipCustomersById}/${id}/customers`,
					method: 'GET',
					params,
				};
			},
			providesTags: ['Sponsorships'],
		}),

		getConsultationsMyCustomers: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.getConsultationsMyCustomers,
					method: 'GET',
					params,
				};
			},
		}),

		getConsultationCategories: build.query<any, any>({
			query: () => {
				return {
					url: endpoints.getConsultationCategories,
					method: 'GET',
				};
			},
		}),

		getAllCastInteractions: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.getAllCastInteractions,
					method: 'GET',
					params: params,
				};
			},
			providesTags: ['Podcasts'],
		}),

		showSingleinteraction: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.showSingleinteraction}/${id}`,
					method: 'GET',
				};
			},
			providesTags: ['Podcasts'],
		}),

		getAllEpisodes: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.getAllEpisodes,
					method: 'GET',
					params,
				};
			},
			providesTags: ['Podcasts'],
		}),

		getAllCastAuthors: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.getvcastAuthor,
					method: 'GET',
					params,
				};
			},
		}),

		getEpisodesByUserId: build.query<any, any>({
			query: ({ id, ...params }) => {
				return {
					url: `${endpoints.getEpisodesByUserId}/${id}/episodes`,
					method: 'GET',
					params: params,
				};
			},
			providesTags: ['Podcasts'],
		}),

		getSubscribersByUserId: build.query<any, any>({
			query: ({ body }) => {
				return {
					url: `${endpoints.getEpisodesByUserId}/${body.id}/customers`,
					method: 'GET',
					params: body,
				};
			},
		}),

		getPodcasts: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.getPodcasts}/${id}`,
					method: 'GET',
				};
			},
		}),

		getPodcastsByUserId: build.query<any, any>({
			query: (params) => {
				return {
					url: `${endpoints.getAllCastsbyUserId}/${params.id}`,
					method: 'GET',
					params: params,
				};
			},
			providesTags: ['Podcasts'],
		}),

		getSavedMyAssetCasts: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.getSavedMyAssetCasts,
					method: 'GET',
					params: params,
				};
			},
			providesTags: ['Podcasts'],
		}),

		getSavedMyAssestEpisodes: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.getSavedMyAssestEpisodes,
					method: 'GET',
					params: params,
				};
			},
			providesTags: ['Podcasts'],
		}),

		getAllCastEpisodeInteractions: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.getAllCastEpisodeInteractions,
					method: 'GET',
					params: params,
				};
			},
		}),

		top10Casts: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.top10Casts,
					method: 'GET',
					params: params,
				};
			},
		}),

		top10CastAuthors: build.query<any, any>({
			query: () => {
				return {
					url: endpoints.top10CastAuthors,
					method: 'GET',
				};
			},
		}),
		getCandidatesByJobId: build.query<any, any>({
			query: ({ id, ...params }) => {
				return {
					url: `${endpoints.getCandidatesByJobId}/${id}`,
					method: 'Get',
					params,
				};
			},
		}),
		getCandidatesByJobIdsBulk: build.query<any, any>({
			query: ({ id, ...params }) => {
				return {
					url: endpoints.getCandidatesByJobIdsBulk,
					method: 'Get',
					params,
				};
			},
			providesTags: ['Jobs'],
		}),

		getSponsorshipCustomersByUserId: build.query<any, any>({
			query: ({ id, ...params }) => {
				return {
					url: `${endpoints.getSponsorshipCustomersById}/${id}`,
					method: 'GET',
					params,
				};
			},
			providesTags: ['Sponsorships'],
		}),

		podCastCategories: build.query<any, any>({
			query: () => {
				return {
					url: endpoints.podCastCategories,
					method: 'GET',
				};
			},
		}),

		getSponsorshipByUserId: build.query<any, any>({
			query: ({ id, ...params }) => {
				return {
					url: `${endpoints.getSponsorshipByUserId}/${id}`,
					method: 'GET',
					params,
				};
			},
			providesTags: ['Sponsorships'],
		}),

		getCoursesByUserId: build.query<any, any>({
			query: ({ id, ...params }) => {
				return {
					url: `${endpoints.getCourseByUserId}/${id}`,
					method: 'GET',
					params,
				};
			},
		}),

		getEnrolledCoursesByUserId: build.query<any, any>({
			query: ({ id, ...params }) => {
				return {
					url: `${endpoints.getEnrolledCourseByUserId}/${id}`,
					method: 'GET',
					params,
				};
			},
		}),

		vBookDownloadRemove: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.vBooksBook}/${id}/remove`,
					method: 'Get',
				};
			},
		}),

		downloadBook: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.downloadEbook}/${id}/download`,
					method: 'Get',
				};
			},
		}),

		getEpisodesByPodCastID: build.query<any, any>({
			query: ({ id, ...params }) => {
				return {
					url: `${endpoints.getEpisodesByPodCastID}/${id}/episodes`,
					method: 'GET',
					params,
				};
			},
			providesTags: ['Podcasts'],
		}),

		getEventDetailsById: build.query<GetEventDetailsByIdResponse, any>({
			query: ({ event }) => {
				return {
					url: `${endpoints.getEventDetailsById}/${event}`,
					method: 'GET',
				};
			},
		}),
		getEventCategories: build.query<any, any>({
			query: () => {
				return {
					url: endpoints.getEventCategories,
					method: 'GET',
				};
			},
		}),
		similarEventBySameAuthor: build.query<any, any>({
			query: ({ eventId }) => {
				return {
					url: `${endpoints.similarEventBySameAuthor}/${eventId}/similar-by-same-author`,
					method: 'GET',
				};
			},
		}),
		similarEventByOtherAuthor: build.query<any, any>({
			query: ({ eventId }) => {
				return {
					url: `${endpoints.similarEventByOtherAuthor}/${eventId}/similar-by-other-authors`,
					method: 'GET',
				};
			},
		}),
		getCustomersByEventId: build.query<any, any>({
			query: ({ event, ...params }) => {
				return {
					url: `${endpoints.getCustomersByEventId}/${event}/customers`,
					method: 'GET',
					params: params,
				};
			},
		}),
		getCustomersByUserId: build.query<any, any>({
			query: ({ user, ...params }) => {
				return {
					url: `${endpoints.getCustomersByUserId}/${user}/customers`,
					method: 'GET',
					params: params,
				};
			},
		}),
		getSimilarSponsorshipBySameAuthor: build.query<any, any>({
			query: ({ sponsorshipId }) => {
				return {
					url: `vbuzz/sponsor-ships/${sponsorshipId}/similar-same`,
					method: 'GET',
				};
			},
			providesTags: ['Jobs'],
		}),

		getSimilarCourseBySameInstructor: build.query<any, any>({
			query: ({ courseId }) => {
				return {
					url: `${endpoints.similarCoursesBySameInstructor}/${courseId}/similar-same`,
					method: 'GET',
				};
			},
			providesTags: ['College'],
		}),

		getSimilarCourseByOtherInstructor: build.query<any, any>({
			query: ({ courseId }) => {
				return {
					url: `${endpoints.similarCoursesByOtherInstructor}/${courseId}/similar-other`,
					method: 'GET',
				};
			},
			providesTags: ['College'],
		}),

		getSimilarSponsorshipByOtherAuthors: build.query<any, any>({
			query: ({ sponsorshipId }) => {
				return {
					url: `vbuzz/sponsor-ships/${sponsorshipId}/similar-other`,
					method: 'GET',
				};
			},
			providesTags: ['Jobs'],
		}),
		getMyEventCustomers: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.getMyEventCustomers,
					method: 'GET',
					params: params,
				};
			},
		}),
		getEventInteractions: build.query<any, any>({
			query: ({ event_id, ...params }) => {
				return {
					url: `events/${event_id}/get-event-interactions`,
					method: 'GET',
					params: params,
				};
			},
			providesTags: ['Events'],
		}),
		showEventInteraction: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.showEventInteraction}/${id}`,
					method: 'GET',
				};
			},
		}),

		allVCast: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.allVCast,
					method: 'GET',
					params,
				};
			},
			providesTags: ['Podcasts'],
		}),
		// Get Service By Service Id

		getServiceShareInteractions: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.getServiceShareInteractions,
					method: 'GET',
					params: params,
				};
			},
			providesTags: ['Services'],
		}),

		getServiceReactionInteractions: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.getServiceReactionInteractions,
					method: 'GET',
					params: params,
				};
			},
			providesTags: ['Services'],
		}),

		getServicePostingInteractions: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.getServicePostingInteractions}${id}`,
					method: 'GET',
					params: id,
				};
			},
			providesTags: ['Services'],
		}),

		getServiceByServiceId: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.getServiceByServiceId}/${id}`,
					method: 'GET',
				};
			},
			providesTags: ['Services'],
		}),
		VlanceServiceById: build.query<any, { id: string }>({
			query: ({ id }) => {
				return {
					url: `${endpoints.VLanceMyprofileService}/${id}`,
					method: 'GET',
				};
			},
		}),
		VlanceService: build.query<any, VLanceServiceRequest>({
			query: (params) => {
				return {
					url: endpoints.VLanceMyprofileService,
					method: 'GET',
					params,
				};
			},
		}),
		VlanceMyService: build.query<any, VLanceServiceRequest>({
			query: (params) => {
				return {
					url: endpoints.VLanceMyServices,
					method: 'GET',
					params,
				};
			},
		}),
		VlanceServiceByUserId: build.query<any, VLanceClientsByUserIdRequest>({
			query: (params) => {
				return {
					url: `${endpoints.VLanceServicesByUserId}/${params.userId}`,
					method: 'GET',
					params,
				};
			},
		}),
		VlanceGalleryByUserId: build.query<any, { userId: any }>({
			query: (params) => {
				return {
					url: `${endpoints.VLanceGalleryByUserId}/${params.userId}`,
					method: 'GET',
					params,
				};
			},
		}),
		VlanceProjects: build.query<any, VLanceMyAssetsMyProjects>({
			query: (params) => {
				return {
					url: endpoints.VLanceMyprofileproject,
					method: 'GET',
					params,
				};
			},
		}),
		VlanceMyAssetsProjects: build.query<any, VLanceMyAssetsMyProjects>({
			query: (params) => {
				return {
					url: endpoints.getMyProjects,
					method: 'GET',
					params,
				};
			},
		}),
		VlanceFreelancerProjects: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.getMyApplication,
					method: 'GET',
					params,
				};
			},
		}),
		VlanceAllClients: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.VLanceMyprofileMYClients,
					method: 'GET',
					params,
				};
			},
		}),
		VlanceSearchAllClients: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.VLanceMyprofileAllClients,
					method: 'GET',
					params,
				};
			},
		}),
		VlanceMyProfileVlancer: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.VLanceMyProfileVlancer,
					method: 'GET',
					params,
				};
			},
		}),
		VlanceSearchlancers: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.lancers,
					method: 'GET',
					params,
				};
			},
		}),

		VlanceMyProfileProject: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.VLanceMyprofileproject,
					method: 'GET',
					params,
				};
			},
		}),

		// vlance projects
		getProjectByProjectId: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.getProjectbyProjectId}/${id}`,
					method: 'GET',
				};
			},
			providesTags: ['Projects'],
		}),

		getProjectInteractions: build.query<any, any>({
			query: ({ id, ...params }) => {
				return {
					url: `${endpoints.getProjectInteractions}`,
					method: 'GET',
					params,
				};
			},
			providesTags: ['Projects'],
		}),

		getVLanceCategories: build.query<any, any>({
			query: () => {
				return {
					url: endpoints.getVLanceCategoreis,
					method: 'GET',
				};
			},
		}),

		getTop10Freelancers: build.query<any, any>({
			query: () => {
				return {
					url: endpoints.getTop10Freelancers,
					method: 'GET',
				};
			},
		}),

		getTop10Clients: build.query<any, any>({
			query: () => {
				return {
					url: endpoints.getTop10Clients,
					method: 'GET',
				};
			},
		}),

		getProjectApplicantsByProjectId: build.query<any, any>({
			query: ({ id, ...params }) => {
				return {
					url: `${endpoints.getProjectApplicantsByProjectId}/${id}`,
					method: 'GET',
					params: params,
				};
			},
		}),

		getSimilarProjectsBySameAuthor: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.getSimilarProjectsBySameAuthor}/${id}/similar-same`,
					method: 'GET',
				};
			},
			providesTags: ['Projects'],
		}),

		getSimilarProjectsByOtherAuthor: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.getSimilarProjectsBySameAuthor}/${id}/similar-other`,
					method: 'GET',
				};
			},
			providesTags: ['Projects'],
		}),

		getInvitationById: build.query<any, { id: string; type: 'lanc' | 'job' }>({
			query: ({ id, type }) => {
				const urls: { [key: string]: string } = {
					lanc: `${endpoints.getInvitationById}${id}`,
					job: `${endpoints.baseJobInvitationById}/get/${id}`,
				};
				console.log('urls[type]', urls[type], type);

				return {
					url: urls[type],
					method: 'GET',
				};
			},
		}),
		getInterviewInvitation: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.getInterviewInvitation}${id}`,
					method: 'GET',
				};
			},
		}),

		getSimilarServicesBySameAuthor: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.getSimilarServicesBySameAuthor}/${id}/similar-same`,
					method: 'GET',
				};
			},
			providesTags: ['Services'],
		}),

		getSimilarServicesByOtherAuthor: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.getSimilarServiceByOtherAuthor}/${id}/similar-other`,
					method: 'GET',
				};
			},
			providesTags: ['Services'],
		}),

		getCastById: build.query<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.getCastById}/${id}`,
					method: 'GET',
				};
			},
			providesTags: ['Podcasts'],
		}),

		podcastbySameAuthor: build.query<any, any>({
			query: ({ CastId }) => {
				return {
					url: `${endpoints.podcastbySameAuthor}/${CastId}/similar-same`,
					method: 'GET',
				};
			},
			providesTags: ['SimilarPodcasts'],
		}),

		podcastbyOtherAuthor: build.query<any, any>({
			query: ({ CastId }) => {
				return {
					url: `${endpoints.podcastbyOtherAuthor}/${CastId}/similar-other`,
					method: 'GET',
				};
			},
			providesTags: ['SimilarPodcasts'],
		}),

		episodesById: build.query<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.episodesById}/${id}`,
					method: 'GET',
				};
			},
			providesTags: ['Podcasts'],
		}),

		vbookCustomersByUser: build.query<any, any>({
			query: ({ id, ...params }) => {
				return {
					url: `${endpoints.vbookCustomersByUser}/${id}`,
					method: 'GET',
					params,
				};
			},
			providesTags: ['Book'],
		}),

		expertsCustomersByUser: build.query<any, any>({
			query: ({ id, ...params }) => {
				return {
					url: `${endpoints.expertsCustomersByUser}/${id}/customers`,
					method: 'GET',
					params,
				};
			},
			providesTags: ['Book'],
		}),

		lancerCustomersByUser: build.query<any, any>({
			query: ({ id, ...params }) => {
				return {
					url: `${endpoints.lanceCustomersByUser}/${id}`,
					method: 'GET',
					params,
				};
			},
			providesTags: ['Services'],
		}),

		booksByUser: build.query<any, any>({
			query: ({ id, ...params }) => {
				return {
					url: `${endpoints.booksByUser}/${id}`,
					method: 'GET',
					params,
				};
			},
			providesTags: ['Book'],
		}),

		consultationsByUser: build.query<any, any>({
			query: ({ id, ...params }) => {
				return {
					url: `${endpoints.consultationsByUser}/${id}`,
					method: 'GET',
					params,
				};
			},
			providesTags: ['Book'],
		}),

		getMyCourses: build.query<any, any>({
			query: ({ id, ...params }) => {
				return {
					url: endpoints.myCourses,
					method: 'GET',
					params,
				};
			},
			providesTags: ['College'],
		}),

		getEnrolledCourses: build.query<any, any>({
			query: ({ id, ...params }) => {
				return {
					url: `${endpoints.enrolledCourses}/${id}`,
					method: 'GET',
					params,
				};
			},
			providesTags: ['College'],
		}),

		getEventsByUserId: build.query<any, any>({
			query: ({ userId, ...params }) => {
				return {
					url: `${endpoints.getEventsByUserId}/${userId}`,
					method: 'GET',
					params,
				};
			},
		}),

		storeVExpert: build.query<GetConsultationServiceResponseType, { id: string }>({
			query: ({ id }) => {
				return {
					url: `${endpoints.storeVExpert}/${id}`,
					method: 'GET',
				};
			},
		}),
		VExpertConsultation: build.query<GetConsultationServiceResponseType, { id: string | string[] }>({
			query: ({ id }) => {
				return {
					url: `${endpoints.storeVExpert}/${id}`,
					method: 'GET',
				};
			},
		}),

		vExperts: build.query<any, getBookAuthorRequestType>({
			query: (body) => {
				return {
					url: endpoints.allVExperts,
					method: 'GET',
					params: body,
				};
			},
		}),
		allConsultations: build.query<any, getAllConsultationsRequestType>({
			query: (body) => {
				return {
					url: endpoints.getConsultations,
					method: 'GET',
					params: body,
				};
			},
			providesTags: ['Expert'],
		}),
		getAllConsultations: build.query<any, getAllConsultationsRequestType>({
			query: (body) => {
				return {
					url: endpoints.allConsultations,
					method: 'GET',
					params: body,
				};
			},
			providesTags: ['Expert'],
		}),

		downloadTicketFile: build.query<any, any>({
			query: (order) => {
				return {
					url: `${endpoints.downloadTicketFile}/${order}`,
					method: 'GET',
				};
			},
		}),

		getSimilarEpisodeBySameAuthor: build.query<any, any>({
			query: ({ episodeId }) => {
				return {
					url: `${endpoints.getSimilarEpisodeBySameAuthor}/${episodeId}`,
					method: 'GET',
				};
			},
			providesTags: ['SimilarPodcasts'],
		}),

		getSimilarEpisodeByOtherAuthor: build.query<any, any>({
			query: ({ episodeId }) => {
				return {
					url: `${endpoints.getSimilarEpisodeByOtherAuthor}/${episodeId}`,
					method: 'GET',
				};
			},
			providesTags: ['SimilarPodcasts'],
		}),

		getSubscribersByPodcastId: build.query<any, any>({
			query: ({ id, ...params }) => {
				return {
					url: `vcast/${id}/customers`,
					method: 'GET',
					params,
				};
			},
			providesTags: ['Podcasts'],
		}),

		subscribeUnSubscribePodcast: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.subscribeUnSubscribePodcast}/${id}`,
					method: 'GET',
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Podcasts', 'SimilarPodcasts']));
			},
		}),

		getMyPodcasts: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.getMyPodcasts,
					method: 'GET',
					params: params,
				};
			},
			providesTags: ['Podcasts'],
		}),

		getConsultationById: build.query<any, { id: number }>({
			query: ({ id }) => {
				return {
					url: `${endpoints.consultationById}/${id}`,
					method: 'GET',
				};
			},
			providesTags: ['Consultation'],
		}),

		deleteConsultationById: build.mutation<any, { id: number }>({
			query: ({ id }) => {
				return {
					url: `${endpoints.consultationById}/${id}`,
					method: 'DELETE',
				};
			},
		}),

		collectiveAllSubscribers: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.collectiveAllSubscribers,
					method: 'GET',
					params: params,
				};
			},
		}),

		getSubscribedPodcasts: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.getSubscribedPodcasts,
					method: 'GET',
					params: params,
				};
			},
			providesTags: ['Podcasts'],
		}),

		getSubscripersByPodcastId: build.query<any, any>({
			query: ({ podcastId, ...params }) => {
				return {
					url: `${endpoints.getSubscripersByPodcastId}/${podcastId}/customers`,
					method: 'GET',
					params: params,
				};
			},
			providesTags: ['Podcasts'],
		}),

		getAllEpisodeInteractions: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.getAllEpisodeInteractions,
					method: 'GET',
					params: params,
				};
			},
			providesTags: ['Podcasts'],
		}),

		getEpisodeInteraction: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.getEpisodeInteraction}/${id}`,
					method: 'GET',
				};
			},
			providesTags: ['Podcasts'],
		}),

		// v tube home page
		getNominationSliderVideos: build.query<NomninationsVideos, any>({
			query: () => {
				return {
					url: `${endpoints.getNominationVideos}`,
					method: 'GET',
				};
			},
		}),

		getFeaturedVideos: build.query<FeaturedVidoesResponseType, any>({
			query: () => {
				return {
					url: `${endpoints.getFeaturedVideos}`,
					method: 'GET',
				};
			},
		}),

		getFeaturedShorts: build.query<FeaturedVidoesResponseType | any, any>({
			query: () => {
				return {
					url: endpoints.getFeaturedShorts,
					method: 'GET',
				};
			},
		}),

		getFeaturedChannels: build.query<FeaturedChannelsResponseType, any>({
			query: () => {
				return {
					url: `${endpoints.getFeaturedChannels}`,
					method: 'GET',
				};
			},
		}),

		getExclusiveChannels: build.query<ExclusiveChannelsResponseType, any>({
			query: () => {
				return {
					url: `${endpoints.getExclusiveChannels}`,
					method: 'GET',
				};
			},
		}),

		getLiveVideos: build.query<LiveVideosListResponseType, any>({
			query: () => {
				return {
					url: `${endpoints.getLiveVideosList}`,
					method: 'GET',
				};
			},
		}),

		getStreamedVideos: build.query<StreamedVideosListResponseType, any>({
			query: () => {
				return {
					url: `${endpoints.getStreamedVideosList}`,
					method: 'GET',
				};
			},
		}),

		getFeaturedVAuthors: build.query<any, any>({
			query: () => {
				return {
					url: endpoints.getFeaturedVAuthors,
					method: 'GET1,',
				};
			},
		}),

		getHashtagsPerModule: build.query<HashtagPerModuleResponseType, any>({
			query: (params) => {
				return {
					url: endpoints.getHashtagsPerModule,
					method: 'Get',
					params: params,
				};
			},
		}),

		getTrendingHashtagsPerModule: build.query<TrendingHashtagResourceType, any>({
			query: (params) => {
				return {
					url: endpoints.getTrendingHashtagsPerModule,
					method: 'Get',
					params: params,
				};
			},
		}),

		getVTubeChannelsByHashtag: build.query<ChannelsByHashtagVTubeResourceType, any>({
			query: (params) => {
				return {
					url: endpoints.getVTubeChannelsByHashtag,
					method: 'Get',
					params: params,
				};
			},
			providesTags: ['Channels'],
		}),

		getVTubeVideosByHashtag: build.query<VideosResourceType, any>({
			query: (params) => {
				return {
					url: endpoints.getVTUbeVideosByHashTag,
					method: 'Get',
					params: params,
				};
			},
		}),
		getVTubeChannelsByHashtagId: build.query<ChannelsByHashtagVTubeResourceType, { id: string; filters: any }>({
			query: ({ id, filters }) => ({
				url: `${endpoints.getVTubeChannelsByHashtagId}/${id}`, // Correctly insert id into URL
				method: 'GET',
				params: filters, // Pass filters as query parameters
			}),
			providesTags: ['Channels'],
		}),
		getVTubeVideosByHashtagId: build.query<VideosResourceType, { id: string; filters: any }>({
			query: ({ id, filters }) => ({
				url: `${endpoints.getVTubeVideosByHashtagId}/${id}`, // Correctly insert id into URL
				method: 'GET',
				params: filters, // Pass filters as query parameters
			}),
		}),
		getVTubeShortsByHashtagId: build.query<ShortsByHashtagResourceType, { id: string; filters: any }>({
			query: ({ id, filters }) => ({
				url: `${endpoints.getVTubeShortsByHashtagId}/${id}`, // Correctly insert id into URL
				method: 'GET',
				params: filters, // Pass filters as query parameters
			}),
		}),

		getMyChannelsByType: build.query<ChannelsByHashtagVTubeResourceType, any>({
			query: (params) => {
				return {
					url: endpoints.getMyChannelsByType,
					method: 'GET',
					params: params,
				};
			},
			providesTags: ['Channels'],
		}),

		getChannelsByUserId: build.query<ChannelsByHashtagVTubeResourceType | any, any>({
			query: ({ id, ...params }) => {
				return {
					url: `${endpoints.getChannelsByUserId}/${id}`,
					method: 'GET',
					params: params,
				};
			},
			providesTags: ['Channels'],
		}),

		getShortsByHashtag: build.query<ShortsByHashtagResourceType, any>({
			query: (params) => {
				return {
					url: endpoints.getShortsByHashtag,
					method: 'GET',
					params: params,
				};
			},
		}),

		getAllVideosWithFilter: build.query<VideosResourceType, any>({
			query: ({ id, ...params }) => {
				return {
					url: endpoints.getAllVidoes,
					method: 'GET',
					params,
				};
			},
		}),

		// v-tube author
		getUserDataForAllModules: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.getUserDataForAllModules}/${id}/modules`,
					method: 'GET',
				};
			},
		}),

		// v-tube my-profile saved
		getAllChannels: build.query<AllChannelsResourceType, any>({
			query: (params) => {
				return {
					url: endpoints.getAllChannels,
					method: 'Get',
					params: params,
				};
			},
			providesTags: ['Channels'],
		}),

		// v-tube my-profile saved
		getAllShorts: build.query<AllShortsResourceType, any>({
			query: (params) => {
				return {
					url: endpoints.getAllShorts,
					method: 'Get',
					params: params,
				};
			},
		}),

		// v-tube my-profile engagements subscriptions
		getSubscriptions: build.query<VideosResourceType, any>({
			query: (params) => {
				return {
					url: endpoints.getSubscriptions,
					method: 'Get',
					params: params,
				};
			},
		}),

		// v-tube my-profile engagements queued
		getQueued: build.query<QueuedDataResourceType, any>({
			query: (params) => {
				return {
					url: endpoints.getQueued,
					method: 'Get',
					params: params,
				};
			},
		}),

		// v-tube my-profile engagements watch-later
		getWatchLater: build.query<WatchLaterVideosResourceType, any>({
			query: (params) => {
				return {
					url: endpoints.getWatchLater,
					method: 'Get',
					params: params,
				};
			},
		}),

		// v-tube my-profile engagements watch-tracker
		getWatchTracker: build.query<WatchTrackerVideosResourceType, any>({
			query: (params) => {
				return {
					url: endpoints.getWatchTracker,
					method: 'Get',
					params: params,
				};
			},
		}),

		// v-tube my-profile engagements subscribed channels
		getSubscribedChannels: build.query<SubscribedChannelsResourceType, any>({
			query: (params) => {
				return {
					url: endpoints.getSubscribedChannels,
					method: 'Get',
					params: params,
				};
			},
			// async onQueryStarted(_, { dispatch, queryFulfilled }) {
			//   await queryFulfilled;
			//   dispatch(queryAPI.util.invalidateTags(['Channels']));
			// },
			providesTags: ['Channels'],
		}),

		getSubscribedChannelsByUserId: build.query<SubscribedChannelsResourceType | any, any>({
			query: ({ id, ...params }) => {
				return {
					url: `${endpoints.getSubscribedChannelsByUserId}/${id}`,
					method: 'GET',
					params,
				};
			},
			providesTags: ['Channels'],
		}),

		// vv-search channels [id] videos
		getVideosByChannelId: build.query<VideosByChannelIdResourceType, any>({
			query: ({ id, ...params }) => {
				return {
					url: `${endpoints.getVideosByChannelId}/${id}`,
					method: 'Get',
					params,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Shorts', 'Videos']));
			},
		}),

		// vv-search channels [id] reactions
		getChannelInteractionsUsingChannelId: build.query<any, any>({
			query: ({ ...params }) => {
				return {
					url: endpoints.getChannelInteractionsByChannelId,
					method: 'Get',
					params,
				};
			},
			providesTags: ['Channels'],
		}),

		showChannelInteractionByInteractionQuery: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.showChannelInteractionByInteractionQuery}/${id}`,
					method: 'GET',
				};
			},
			providesTags: ['Channels'],
		}),

		getSubscribersByVAuthorId: build.query<any, any>({
			query: ({ id, ...params }) => {
				return {
					url: `${endpoints.getAllSubscribersByUserId}/${id}`,
					method: 'GET',
					params,
				};
			},
		}),

		// vv-search channels [id] subscribers
		getSubscribersByChannelId: build.query<ConnectedUserResource | any, any>({
			query: ({ id, ...params }) => {
				return {
					url: `${endpoints.getSubscribersByChannelId}/${id}`,
					method: 'GET',
					params,
				};
			},
		}),

		getAuthorSubscribersForMyProfile: build.query<any, any>({
			query: ({ ...params }) => {
				return {
					url: endpoints.getAuthorSubscribers,
					method: 'GET',
					params,
				};
			},
		}),

		getVTubeRandomForSidebar: build.query<any, any>({
			query: () => {
				return {
					url: endpoints.getVTubeRandomForSidebar,
					method: 'GET',
				};
			},
		}),

		getSidebarRandomVidoesAndPlaylists: build.query<any, any>({
			query: () => {
				return {
					url: endpoints.getSidebarRandomVidoesAndPlaylists,
					method: 'GET',
				};
			},
		}),

		getNextRandomVideo: build.query<any, any>({
			query: ({ ...params }) => {
				return {
					url: endpoints.getNextRandomVideo,
					method: 'GET',
					params,
				};
			},
		}),

		getSavedVideosOrShorts: build.query<any, any>({
			query: ({ ...params }) => {
				return {
					url: endpoints.getSavedVideosOrShorts,
					method: 'GET',
					params,
				};
			},
		}),

		getAllSubscribers: build.query<any, any>({
			query: ({ ...params }) => {
				return {
					url: endpoints.getAllSubscribers,
					method: 'GET',
					params,
				};
			},
		}),

		// vv-search channels [id] about
		getChannelInfoByChannelId: build.query<ChannelsByHashtagVTubeResourceType | any, any>({
			query: (id) => {
				return {
					url: `${endpoints.getChannelInfoByChannelId}/${id}`,
					method: 'GET',
				};
			},
			providesTags: ['Channels'],
		}),

		clearVideoHistory: build.query<any, any>({
			query: () => {
				return {
					url: endpoints.clearVideoHistory,
					method: 'GET',
				};
			},
		}),

		toggleVideoHistory: build.query<any, any>({
			query: () => {
				return {
					url: endpoints.toggleVideoHistory,
					method: 'GEt',
				};
			},
		}),

		// vv- search vidoes [id] details
		getVideoDetailsByVideoId: build.query<VideosResourceType | any, any>({
			query: ({ id, ...params }) => {
				return {
					url: `${endpoints.getVideoByVideoId}/${id}`,
					method: 'Get',
					params,
				};
			},
		}),
		getVideoDetailsByVideoIdPlaylist: build.query<VideosResourceType, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.getVideoByVideoId}/${id}`,
					method: 'Get',
				};
			},
		}),

		// vv-search videos [id] comment
		getVideoInteractionsByVideoId: build.query<VidoeInteractionResoruceType | any, any>({
			query: ({ ...params }) => {
				return {
					url: `${endpoints.getVideoInteractions}`,
					method: 'Get',
					params,
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Videos']));
			},
		}),

		getVTubeCategories: build.query<VTubeCategoryDataResource, any>({
			query: () => {
				return {
					url: endpoints.getVTubeCategories,
					method: 'Get',
				};
			},
		}),

		getTop10Channels: build.query<ChannelsByHashtagVTubeResourceType, any>({
			query: ({ ...params }) => {
				return {
					url: endpoints.getTop10Channels,
					method: 'Get',
					params,
				};
			},
			providesTags: ['Channels'],
		}),

		getTop10VTubeFeaturedAuthors: build.query<VTubeAuthorResourceType, any>({
			query: () => {
				return {
					url: endpoints.getTop10VTubeAuthors,
					method: 'Get',
				};
			},
		}),

		getVtubeAuthors: build.query<VTubeAuthorResourceType, any>({
			query: ({ ...params }) => {
				return {
					url: endpoints.getVTubeAuthors,
					method: 'Get',
					params,
				};
			},
		}),

		getSimilarChannelsBySameAuthor: build.query<ChannelsByHashtagVTubeResourceType, any>({
			query: ({ id, ...params }) => {
				return {
					url: endpoints.getSimilarChannelsBySameAuthor,
					method: 'GET',
					params,
				};
			},
			providesTags: ['Channels'],
		}),

		getSimilarChannelsByOtherAuthor: build.query<ChannelsByHashtagVTubeResourceType, any>({
			query: ({ id, ...params }) => {
				return {
					url: endpoints.getSimilarChannelsByOtherAuthor,
					method: 'GET',
					params,
				};
			},
			providesTags: ['Channels'],
		}),

		getSimilarVideosBySameAuthor: build.query<VideosResourceType, any>({
			query: ({ id, ...params }) => {
				return {
					url: `${endpoints.getSimilarVideo}/${id}/similar-same`,
					method: 'GET',
					params,
				};
			},
		}),

		getSimilarVideosByOtherAuthor: build.query<VideosResourceType, any>({
			query: ({ id, ...params }) => {
				return {
					url: `${endpoints.getSimilarVideo}/${id}/similar-other`,
					method: 'GET',
					params,
				};
			},
		}),

		getTop10Servies: build.query<any, any>({
			query: () => {
				return {
					url: endpoints.getTop10Services,
					method: 'GET',
				};
			},
		}),

		getPlaylistsByChannelId: build.query<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.getPlaylistsUsingChannelId}/${id}`,
					method: 'GET',
				};
			},
		}),

		showPlaylistByPlaylistId: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.showPlaylistUsingPlaylistId}/${id}`,
					method: 'GET',
				};
			},
		}),

		getServiceAvailableSlots: build.query<any, any>({
			query: ({ service_id, ...params }) => {
				return {
					url: `${endpoints.getServiceAvailableSlots}/${service_id}/available-slots`,
					method: 'GET',
					params: params,
				};
			},
		}),

		getAllStores: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.getAllStores,
					method: 'GET',
					params: params,
				};
			},
		}),
		top10AuthorsStore: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.top10AuthorsStore,
					method: 'GET',
					params: params,
				};
			},
		}),
		top10AuthorsStoreByCountry: build.query<any, any>({
			query: ({ countryID, ...params }) => {
				return {
					url: `${endpoints.top10AuthorsStoreByCountry}/${countryID}`,
					method: 'GET',
					params: params,
				};
			},
		}),
		top10Stores: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.top10Stores,
					method: 'GET',
					params: params,
				};
			},
		}),
		getSimilarStoresBySameAuthor: build.query<any, any>({
			query: ({ storeId }) => {
				return {
					url: `${endpoints.getSimilarStoresBySameAuthor}/${storeId}/similar-same`,
					method: 'GET',
				};
			},
		}),
		getSimilarStoresByOtherAuthor: build.query<any, any>({
			query: ({ storeId }) => {
				return {
					url: `${endpoints.getSimilarStoresByOtherAuthor}/${storeId}/similar-other`,
					method: 'GET',
				};
			},
		}),
		latestStores: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.latestStores,
					method: 'GET',
					params: params,
				};
			},
		}),
		featuresAuthorsStores: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.featuresAuthorsStores,
					method: 'GET',
					params: params,
				};
			},
		}),
		savedDownloadMyAssets: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.savedDownloadMyAssets,
					method: 'GET',
					params: params,
				};
			},
		}),
		showStoreById: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.showStoreById}/${id}`,
					method: 'GET',
				};
			},
		}),
		getAllStorePosts: build.query<any, any>({
			query: (params) => {
				return {
					url: `${endpoints.getAllStorePosts}`,
					method: 'GET',
					params: params,
				};
			},
		}),
		getStoreInteraction: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.getStoreInteraction}/${id}`,
					method: 'GET',
				};
			},
		}),
		getAllProducts: build.query<any, any>({
			query: (params) => {
				return {
					url: `${endpoints.getAllProducts}`,
					method: 'GET',
					params: params,
				};
			},
		}),

		getServiceInteractions: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.getServiceInteractions,
					method: 'GET',
					params: params,
				};
			},
			providesTags: ['Services'],
		}),
		showServiceInteraction: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.showServiceInteraction}${id}`,
					method: 'GET',
				};
			},
			providesTags: ['Services'],
		}),
		getSimilarServiceBySameAuthor: build.query<any, any>({
			query: ({ id }) => {
				return {
					url: `v-lance/service/${id}/similar-same`,
					method: 'GET',
				};
			},
			providesTags: ['Services'],
		}),

		getSimilarServiceByOtherAuthors: build.query<any, any>({
			query: ({ id }) => {
				return {
					url: `v-lance/service/${id}/similar-other`,
					method: 'GET',
				};
			},
			providesTags: ['Services'],
		}),

		showProjectInteraction: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.showProjectInteraction}/${id}`,
					method: 'GET',
				};
			},
			providesTags: ['Projects'],
		}),
		getProductCategory: build.query<any, any>({
			query: () => {
				return {
					url: `${endpoints.getProductsCategories}`,
					method: 'GET',
				};
			},
		}),
		savedDownloadMyProducts: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.savedDownloadMyProducts,
					method: 'GET',
					params: params,
				};
			},
		}),
		//
		getVmartCustomers: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.getMyCustomers,
					method: 'GET',
					params: params,
				};
			},
		}),

		getMyServices: build.query<any, any>({
			query: (params) => {
				return {
					url: `${endpoints.getMyServices}`,
					method: 'GET',
					params: params,
				};
			},
		}),

		getAllCustomersByServiceId: build.query<any, any>({
			query: ({ service_id, ...rest }) => {
				return {
					url: `${endpoints.getAllCustomersByServiceId}/${service_id}`,
					method: 'GET',
					params: rest,
				};
			},
		}),

		webinarShow: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.webinarShow}/${id}`,
					method: 'GET',
				};
			},
		}),

		getWebinarDetailsById: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.getWebinarDetailsById}/${id}`,
					method: 'GET',
				};
			},
			providesTags: ['Webinars'],
		}),
		getAllWebinarInteractions: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.getAllWebinarInteractions,
					method: 'GET',
					params: params,
				};
			},
			providesTags: ['Webinars'],
		}),
		showWebinarInteraction: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.showWebinarInteraction}/${id}`,
					method: 'GET',
				};
			},
			providesTags: ['Webinars'],
		}),
		getSimilarWebinarBySameAuthor: build.query<any, any>({
			query: (id) => {
				return {
					url: `vwebinar/webinars/${id}/similar-by-same-author`,
					method: 'GET',
				};
			},
			providesTags: ['Webinars'],
		}),

		getSimilarWebinarByOtherAuthors: build.query<any, any>({
			query: (id) => {
				return {
					url: `vwebinar/webinars/${id}/similar-by-different-authors`,
					method: 'GET',
				};
			},
			providesTags: ['Webinars'],
		}),
		getAllPollsByWebinarId: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.getAllPollsByWebinarId}/${id}`,
					method: 'GET',
				};
			},
		}),
		deletePollById: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.deletePollById}/${id}`,
					method: 'DELETE',
				};
			},
		}),
		updatePollById: build.query<any, any>({
			query: ({ id, body }) => {
				return {
					url: `${endpoints.updatePollById}/${id}`,
					method: 'PUT',
					body,
				};
			},
		}),
		postCreateWebinarPoll: build.query<any, any>({
			query: ({ body }) => {
				return {
					url: endpoints.postCreateWebinarPoll,
					method: 'POST',
					body,
				};
			},
		}),
		postVoteOnPollAnswer: build.query<any, any>({
			query: (answerId) => {
				return {
					url: `${endpoints.postVoteOnPollAnswer}/${answerId}`,
					method: 'POST',
				};
			},
		}),
		getAllOffersByWebinarId: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.getAllOffersByWebinarId}/${id}`,
					method: 'GET',
				};
			},
		}),
		getOfferById: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.getOfferById}/${id}`,
					method: 'GET',
				};
			},
		}),
		deleteOfferById: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.deleteOfferById}/${id}`,
					method: 'DELETE',
				};
			},
		}),
		updateOfferById: build.query<any, any>({
			query: ({ id, body }) => {
				return {
					url: `${endpoints.updateOfferById}/${id}`,
					method: 'PUT',
					body,
				};
			},
		}),
		postCreateWebinarOffer: build.query<any, any>({
			query: ({ body }) => {
				return {
					url: endpoints.postCreateWebinarOffer,
					method: 'POST',
					body,
				};
			},
		}),
		getAllHandoutsByWebinarId: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.getAllHandoutsByWebinarId}/${id}`,
					method: 'GET',
				};
			},
		}),
		getHandoutById: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.getHandoutById}/${id}`,
					method: 'GET',
				};
			},
		}),
		deleteHandoutById: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.deleteHandoutById}/${id}`,
					method: 'DELETE',
				};
			},
		}),
		updateHandoutById: build.query<any, any>({
			query: ({ id, body }) => {
				return {
					url: `${endpoints.updateHandoutById}/${id}`,
					method: 'PUT',
					body,
				};
			},
		}),
		postCreateWebinarHandout: build.query<any, any>({
			query: ({ body }) => {
				return {
					url: endpoints.postCreateWebinarHandout,
					method: 'POST',
					body,
				};
			},
		}),
		postJoinAutomatedWebinar: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.postJoinAutomatedWebinar}/${id}/join`,
					method: 'POST',
				};
			},
		}),
		postJoinHybridWebinar: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.postJoinHybridWebinar}/${id}/join`,
					method: 'POST',
				};
			},
		}),
		postJoinLiveWebinar: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.postJoinLiveWebinar}/${id}/join`,
					method: 'POST',
				};
			},
		}),
		putUpdateWebinarSettings: build.query<any, any>({
			query: ({ id, body }) => {
				return {
					url: `${endpoints.postJoinLiveWebinar}/${id}/settings`,
					method: 'PUT',
					body,
				};
			},
		}),
		getWebinarSettings: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.postJoinLiveWebinar}/${id}/settings`,
					method: 'GET',
				};
			},
		}),
		getAttendeesByWebinarId: build.query<any, any>({
			query: ({ podcastId, queryParams }) => {
				return {
					url: `${endpoints.getAttendeesByWebinarId}/${podcastId}`,
					method: 'GET',
					params: queryParams,
				};
			},
		}),

		getFreelancersByUserId: build.query<any, any>({
			query: ({ id, ...rest }) => {
				return {
					url: `${endpoints.getFreelancersByUserId}/${id}`,
					method: 'GET',
					params: rest,
				};
			},
		}),
		showCourse: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.showCourse}/${id}`,
					method: 'GET',
				};
			},
		}),

		getMyWebinars: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.getMyWebinars,
					method: 'GET',
					params,
				};
			},
		}),

		getProjectsByUserId: build.query<any, any>({
			query: ({ id, ...params }) => {
				return {
					url: `${endpoints.getProjectsByUserId}/${id}`,
					method: 'GET',
					params,
				};
			},
		}),

		getWebinarAttendees: build.query<any, any>({
			query: ({ id, ...params }) => {
				return {
					url: `${endpoints.getWebinarAttendees}/${id}`,
					method: 'GET',
					params,
				};
			},
		}),

		top10Products: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.top10Products,
					method: 'GET',
					params: params,
				};
			},
		}),
		getTop10AuthorsInProduct: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.getTop10AuthorsInProduct,
					method: 'GET',
					params: params,
				};
			},
		}),
		getProductCategories: build.query<any, any>({
			query: () => {
				return {
					url: `${endpoints.getProductsCategories}`,
					method: 'GET',
				};
			},
		}),

		getCourseCustomersByCourseId: build.query<any, any>({
			query: ({ id, ...params }) => {
				return {
					url: `${endpoints.getCourseStudentByCourseId}/${id}`,
					method: 'GET',
					params,
				};
			},
		}),
		getCourseCustomersByUserId: build.query<any, any>({
			query: ({ id, ...params }) => {
				return {
					url: `${endpoints.getCourseStudentByUserId}/${id}`,
					method: 'GET',
					params,
				};
			},
		}),

		getWebinarCategories: build.query<any, any>({
			query: () => {
				return {
					url: endpoints.getWebinarCategories,
					method: 'GET',
				};
			},
		}),

		getWebinarTop10Authors: build.query<
			{
				data: ConnectedUserResource[];
			},
			any
		>({
			query: () => {
				return {
					url: endpoints.getWebinarTop10Authors,
					method: 'GET',
				};
			},
		}),

		getProductById: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.productById}/${id}`,
					method: 'GET',
				};
			},
		}),
		getSimilarProductsBySameAuthor: build.query<any, any>({
			query: ({ ProductId }) => {
				console.log('id same', ProductId);
				return {
					url: `${endpoints.getSimilarProductsBySameAuthor}/${ProductId}/similar-same`,
					method: 'GET',
				};
			},
		}),
		getSimilarProductsByOtherAuthors: build.query<any, any>({
			query: ({ ProductId }) => {
				console.log('id other', ProductId);

				return {
					url: `${endpoints.getSimilarProductsByOtherAuthor}/${ProductId}/similar-other`,
					method: 'GET',
				};
			},
		}),
		getAllProductPosts: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.getAllProductPosts,
					method: 'GET',
					params: params,
				};
			},
		}),
		getProductInterActionById: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.getProductInterActionById}/${id}`,
					method: 'GET',
				};
			},
		}),
		getProductsByProductId: build.query<any, any>({
			query: (params) => {
				return {
					url: `${endpoints.productsByProductID}/${params.productId}`,
					method: 'GET',
					params,
				};
			},
		}),
		getProductInteractions: build.query<any, any>({
			query: (params) => {
				return {
					url: `${endpoints.getProductInteraction}/${params.productId}`,
					method: 'GET',
					params: params,
				};
			},
			// providesTags: ['Products'],
		}),
		getProductAuthors: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.getProductAuthors,
					method: 'GET',
					params: params,
				};
			},
		}),

		getMyAttendees: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.getMyAttendees,
					method: 'GET',
					params,
				};
			},
		}),

		getUserAttendees: build.query<any, any>({
			query: ({ id, ...params }) => {
				return {
					url: `${endpoints.getUserAttendees}/${id}`,
					method: 'GET',
					params,
				};
			},
		}),

		getUserWebinars: build.query<any, any>({
			query: ({ id, ...params }) => {
				return {
					url: `${endpoints.getUserWebinars}/${id}`,
					method: 'GET',
					params,
				};
			},
		}),

		getAllWebinars: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.webinarShow,
					method: 'GET',
					params,
				};
			},
		}),

		getAllFreelancersByProjectId: build.query<any, any>({
			query: ({ project_id, ...rest }) => {
				return {
					url: `${endpoints.getAllFreelancersByProjectId}/${project_id}`,
					method: 'GET',
					params: rest,
				};
			},
		}),
		getAnnouncementByCourse: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.getAnnouncementByCourse}/${id}`,
					method: 'GET',
				};
			},
		}),

		getPostsByHashtagsVNation: build.query<any, any>({
			query: ({ ...params }) => {
				return {
					url: endpoints.getPostsByHashtagsVNation,
					method: 'GET',
					params,
				};
			},
			providesTags: ['TimelinePosts'],
		}),

		getSocialTimelinePostInteraction: build.query<any, any>({
			query: ({ ...params }) => {
				return {
					url: endpoints.getSocialTimelinePostInteraction,
					method: 'GET',
					params,
				};
			},
			providesTags: ['TimelinePosts'],
		}),

		getTmelinePostsForHomePageVNation: build.query<any, any>({
			query: ({ ...params }) => {
				return {
					url: endpoints.getPostsForHomePageVNation,
					method: 'GET',
					params: params,
				};
			},
			providesTags: ['TimelinePosts', 'socialPagePosts', 'socialGroupPosts'],
		}),

		getVNationSocialTimeLinePostsInteraction: build.query<any, any>({
			query: ({ ...params }) => {
				return {
					url: endpoints.getVNationSocialTimeLinePostsInteraction,
					method: 'GET',
					params: params,
				};
			},
			providesTags: ['TimelinePosts'],
		}),

		getMyAssetsSocialPages: build.query<any, any>({
			query: ({ ...params }) => {
				return {
					url: endpoints.getMyAssetsSocialPages,
					method: 'GET',
					params,
				};
			},
		}),

		getVvSearchSocialPages: build.query<any, any>({
			query: ({ ...params }) => {
				return {
					url: endpoints.getVvSearchSocialPages,
					method: 'GET',
					params,
				};
			},
		}),

		getVvSearchAuthorPage: build.query<any, any>({
			query: ({ ...params }) => {
				return {
					url: endpoints.getVvSearchAuthorPage,
					method: 'GET',
					params,
				};
			},
		}),

		getAllHosts: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.getAllHosts,
					method: 'GET',
					params,
				};
			},
		}),

		getUnitsByCourse: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.getUnitsByCourse}/${id}`,
					method: 'GET',
				};
			},
		}),
		getTop10SocialPages: build.query<any, any>({
			query: () => {
				return {
					url: endpoints.getTop10SocialPages,
					mehtod: 'GET',
				};
			},
		}),

		getTop10SocialGroups: build.query<any, any>({
			query: () => {
				return {
					url: endpoints.getTop10SocialGroups,
					method: 'GET',
				};
			},
		}),

		getVNationCategories: build.query<any, any>({
			query: () => {
				return {
					url: endpoints.getVNationCategories,
					method: 'GET',
				};
			},
		}),

		getTop10AuthorsVNation: build.query<any, any>({
			query: ({ ...params }) => {
				return {
					url: endpoints.getTop10AuthorsVNation,
					method: 'GET',
					params,
				};
			},
		}),

		getMyAssetsSocialGroups: build.query<any, any>({
			query: ({ ...params }) => {
				return {
					url: endpoints.getMyAssetsSocialGroups,
					method: 'GET',
					params,
				};
			},
		}),

		getSearchGroupsForVNation: build.query<any, any>({
			query: ({ ...params }) => {
				return {
					url: endpoints.getSearchGroupsVNation,
					method: 'GET',
					params,
				};
			},
		}),

		getPostByGroupIdVNation: build.query<any, any>({
			query: ({ ...params }) => {
				return {
					url: endpoints.getPostByGroupIdVNation,
					method: 'GET',
					params,
				};
			},
			providesTags: ['socialGroupPosts'],
		}),

		getPostByPageIdVNation: build.query<any, any>({
			query: ({ ...params }) => {
				return {
					url: endpoints.getPostByPageIdVNation,
					method: 'GET',
					params,
				};
			},
			providesTags: ['socialPagePosts'],
		}),

		getGroupMembersByGroupId: build.query<any, any>({
			query: ({ ...params }) => {
				return {
					url: `${endpoints.getGroupMembersByGroupId}/${params.id}/members`,
					method: 'GET',
					params,
				};
			},
		}),

		getPageFollowersByPageId: build.query<any, any>({
			query: ({ id, ...params }) => {
				return {
					url: `${endpoints.getPageFollowersByPageId}/${id}`,
					method: 'GET',
					params,
				};
			},
		}),

		newGetPageFollowersByPageId: build.query<any, any>({
			query: ({ id, ...params }) => {
				return {
					url: `${endpoints.newGetPageFollowersByPageId}/${id}/followers`,
					method: 'GET',
					params,
				};
			},
		}),

		getSocialPageInteractions: build.query<any, any>({
			query: ({ ...params }) => {
				return {
					url: endpoints.getSocialPageInteractions,
					method: 'GET',
					params,
				};
			},
		}),

		getSocialGroupInteraction: build.query<any, any>({
			query: ({ ...params }) => {
				return {
					url: endpoints.getSocialGroupInteraction,
					method: 'GET',
					params,
				};
			},
		}),
		showSocialGroupInteraction: build.query<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.showSocialGroupInteraction}/${id}`,
					method: 'GET',
				};
			},
		}),
		showSocialPageInteraction: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.showSocialPageInteraction}/${id}`,
					method: 'GET',
				};
			},
		}),

		getGroupDataByGroupId: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.getGroupDataByGroupId}/${id}`,
					method: 'GET',
				};
			},
		}),

		getSocialPageDataByPageId: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.getSocialPageDataByPageId}/${id}`,
					method: 'GET',
				};
			},
			providesTags: ['socialPagePosts'],
		}),

		getSimilarGroupBySameGroupId: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.getSimilarGroupBySameGroupId}/${id}`,
					method: 'GET',
				};
			},
		}),
		getSimilarGroupByOtherGroupId: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.getSimilarGroupByOtherGroupId}/${id}`,
					method: 'GET',
				};
			},
		}),
		getSimilarSocialPageBySamePageId: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.getSimilarSocialPageBySamePageId}/${id}`,
					method: 'GET',
				};
			},
		}),
		getSimilarSocialPageByOtherPageId: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.getSimilarSocialPageByOtherPageId}/${id}`,
					method: 'GET',
				};
			},
		}),

		getProductCustomerById: build.query<any, any>({
			query: ({ userId, ...params }) => {
				return {
					url: `${endpoints.productCustomerById}/${userId}`,
					method: 'GET',
					params: params,
				};
			},
		}),

		getUserProducts: build.query<any, any>({
			query: ({ userId, ...params }) => {
				return {
					url: `${endpoints.getUserProducts}/${userId}`,
					method: 'GET',
					params: params,
				};
			},
		}),

		getWishlists: build.query<GetWislistResponseType, GetWislistRequestType>({
			query: (params) => {
				return {
					url: endpoints.getWishlists,
					method: 'GET',
					params,
				};
			},
			providesTags: ['Wishlists'],
		}),

		getStoreById: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.getStoreById}/${id}`,
					method: 'GET',
				};
			},
		}),
		getStoresByUserId: build.query<any, any>({
			query: ({ id, ...params }) => {
				return {
					url: `${endpoints.getStoresByUserId}/${id}`,
					method: 'GET',
					params: params,
				};
			},
		}),
		getCustomersByProductId: build.query<any, any>({
			query: ({ id, ...params }) => {
				return {
					url: `${endpoints.getCustomersByProductId}/${id}`,
					method: 'GET',
					params: params,
				};
			},
		}),
		getProductByStoreId: build.query<any, any>({
			query: ({ storeId, ...params }) => {
				return {
					url: `${endpoints.getProductByStoreId}/${storeId}`,
					method: 'GET',
					params: params,
				};
			},
		}),
		getCustomersByStoreId: build.query<any, any>({
			query: ({ storeId, ...params }) => {
				return {
					url: `${endpoints.getCustomersByStoreId}/${storeId}`,
					method: 'GET',
					params: params,
				};
			},
		}),

		getCartSummary: build.query<GetCartSummaryResponseType, any>({
			query: () => {
				return {
					url: endpoints.getCartSummary,
					method: 'GET',
				};
			},
			providesTags: ['Cart'],
		}),

		getAllCoupons: build.query<any, any>({
			query: () => {
				return {
					url: endpoints.allCoupons,
					method: 'GET',
				};
			},
			providesTags: ['Coupons'],
		}),

		clearCart: build.query<any, any>({
			query: () => {
				return {
					url: endpoints.clearCart,
					method: 'GET',
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Cart']));
			},
		}),

		getShippingAddress: build.query<any, any>({
			query: (module_enum) => {
				return {
					url: `${endpoints.getShippingAddress}/${module_enum}`,
					method: 'GET',
				};
			},
		}),

		getShippingOptionsById: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.getShippingOptionsById,
					method: 'GET',
					params,
				};
			},
		}),

		showSocialPage: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.showSocialPage}/${id}`,
					method: 'GET',
				};
			},
		}),
		showSocialGroup: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.showSocialGroup}/${id}`,
					method: 'GET',
				};
			},
		}),

		getSocialGroupPostInteraction: build.query<any, any>({
			query: ({ ...params }) => {
				return {
					url: endpoints.getSocialGroupPostInteraction,
					method: 'GET',
					params,
				};
			},
			providesTags: ['socialGroupPosts'],
		}),

		showSocialGroupPostInteraction: build.query<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.showSocialGroupPostInteraction}/${id}`,
					method: 'GET',
				};
			},
			providesTags: ['socialGroupPosts'],
		}),

		getSocialPagePostInteraction: build.query<any, any>({
			query: ({ ...params }) => {
				return {
					url: endpoints.getSocialPagePostInteraction,
					method: 'GET',
					params,
				};
			},
			providesTags: ['socialPagePosts'],
		}),

		showSocialPagePostInteraction: build.query<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.showSocialPagePostInteraction}/${id}`,
					method: 'GET',
				};
			},
			providesTags: ['socialPagePosts'],
		}),

		showSocialTimelinePostInteraction: build.query<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.showSocialTimelinePostInteraction}/${id}`,
					method: 'GET',
				};
			},
			providesTags: ['TimelinePosts'],
		}),

		showProductsByStoreId: build.query<any, any>({
			query: ({ id, ...params }) => {
				return {
					url: `${endpoints.showProductsByStoreId}/${id}`,
					method: 'GET',
					params: params,
				};
			},
		}),

		getMySubscripers: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.getMySubscripers,
					method: 'GET',
					params: params,
				};
			},
			providesTags: ['Podcasts'],
		}),

		instructorsByCourse: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.instructorsByCourse}/${id}`,
					method: 'GET',
				};
			},
		}),
		getShareTimelines: build.query<any, any>({
			query: (params) => {
				return {
					url: endpoints.shareTimeline,
					method: 'GET',
					params: params,
				};
			},
		}),

		getMyReferrals: build.query<any, { token: string }>({
			query: ({ token }) => ({
				url: endpoints.myreferrals,
				method: 'GET',
				params: { token },
			}),
		}),
		getPlans: build.query<any, any>({
			query: (...params) => ({
				url: endpoints.plans,
				method: 'GET',
				params,
			}),
		}),
		findNotesByLessonId: build.query<any, any>({
			query: ({ id, ...params }) => {
				return {
					url: `${endpoints.findNotesByLessonId}/${id}`,
					method: 'GET',
					params: params,
				};
			},
			providesTags: ['Notes'],
		}),

		//wallet
		getMyWallet: build.query<any, any>({
			query: (wallets_enum: WALLETS_ENUM_TYPE) => {
				return {
					url: `${endpoints.getMyWallet}`,
					method: 'GET',
					params: { wallets_enum },
				};
			},
			providesTags: ['Wallet'],
		}),

		getOrders: build.query<any, { type: string; search: string; order?: string; page?: number }>({
			query: ({ type, search, order, page }) => ({
				url: `${endpoints.orders}?page=${page}`,
				method: 'GET',
				params: {
					type,
					search,
					...(order && { order }),
				},
			}),
		}),

		// API slice !need action
		getOrdersItems: build.query<any, { type: string; search: string; order?: string; page: number; id: string }>({
			query: ({ type, search, order, page, id }) => ({
				url: `${endpoints.orders}/${id}`,
				method: 'GET',
				params: { type, search, order, page },
			}),
		}),

		// Download files
		DownloadInvoice: build.query<any, any>({
			query: (id) => ({
				url: `${endpoints.downloadInvoice}/${id}/invoice`,
				method: 'GET',
			}),
		}),

		getDataByAsset: build.query<any, any>({
			query: ({ page, name, limit, search, startDate, endDate }) => ({
				url: endpoints.getDataByAsset,
				method: 'GET',
				params: {
					asset_name: name,
					// Optional Params
					...(limit && { limit }),
					...(page && { page }),
					...(search && { search }),
					...(startDate && { start_date: startDate }),
					...(endDate && { end_date: endDate }),
				},
			}),
		}),
		getAdSpots: build.query<any, any>({
			query: ({ search, startDate, endDate }) => ({
				url: endpoints.adspot,
				method: 'GET',
				params: {
					...(search && { search }),
					...(startDate && { start_date: startDate }),
					...(endDate && { end_date: endDate }),
				},
			}),
		}),

		getAdminBadges: build.query<any, any>({
			query: ({ token }) => ({
				url: endpoints.adminBadges,
				method: 'GET',
				params: { token },
			}),
		}),
		getActiveAds: build.query<any, any>({
			query: ({ page, limit, startDate, endDate }) => {
				return {
					url: `${endpoints.getActiveAds}`,
					method: 'GET',
					params: {
						...(limit && { limit }),
						...(page && { page }),
						...(startDate && { start_date: startDate }),
						...(endDate && { end_date: endDate }),
					},
				};
			},
		}),
		getAdsBySpotsIds: build.query<any, any>({
			query: (spot_ids) => {
				return {
					url: `${endpoints.getAdsBySpotsIds}`,
					method: 'PUT',
					body: { spot_ids },
				};
			},
		}),
		getAllWallet: build.query<any, any>({
			query: ({ type, startDate, endDate, limit }) => ({
				url: `${endpoints.getAllWallet}`,
				method: 'GET',
				params: {
					...(type && { type }), // "funding" or "withdrawal"
					// ...(startDate && { start_date: startDate }),
					// ...(endDate && { end_date: endDate }),
					// ...(limit && { limit }),
					// ...(page && { page }),
				},
			}),
		}),

		GetReportsForAdmin: build.query<any, any>({
			query: ({ ...params }) => {
				return {
					url: `${endpoints.AdminReports}`,
					method: 'GET',
					params: params,
				};
			},
		}),
		getReportById: build.query<any, any>({
			query: (id) => {
				return {
					url: `${endpoints.AdminReports}${id}`,
					method: 'GET',
				};
			},
		}),
		// CONTRACTS
		// SERVICE CONTRACTS QUERIES
		getAllServiceContractsAsSeller: build.query<any, any>({
			query: ({ ...params }) => {
				return {
					url: `${endpoints.getAllServiceContractsAsSeller}`,
					method: 'GET',
					params: params,
				};
			},
		}),
		getAllServiceContractsAsCustomer: build.query<any, any>({
			query: ({ ...params }) => {
				return {
					url: `${endpoints.getAllServiceContractsAsCustomer}`,
					method: 'GET',
					params: params,
				};
			},
		}),
		getServiceContractById: build.query<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.getServiceContractById}/${id}`,
					method: 'GET',
				};
			},
		}),
		getServiceContractRequestById: build.query<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.getServiceContractRequestById}/${id}`,
					method: 'GET',
				};
			},
		}),
		postServiceContractActionById: build.query<any, any>({
			query: ({ id, action }) => {
				return {
					url: `${endpoints.postServiceContractActionById}/${id}`,
					method: 'POST',
					body: { status_enum: action },
				};
			},
		}),

		postServiceContractDeliveryActionById: build.query<any, any>({
			query: ({ id, action }) => {
				return {
					url: `${endpoints.postServiceContractDeliveryActionById}/${id}`,
					method: 'POST',
					body: { status_enum: action },
				};
			},
		}),
		// SPONSORSHIP CONTRACTS QUERIES
		getAllSponsorshipContractsAsSeller: build.query<any, any>({
			query: (body) => {
				return {
					url: `${endpoints.getAllSponsorshipContractsAsSeller}`,
					method: 'GET',
					params: body,
				};
			},
			providesTags: ['Sponsorships'],
		}),
		getAllSponsorshipContractsAsCustomer: build.query<any, any>({
			query: (body) => {
				return {
					url: `${endpoints.getAllSponsorshipContractsAsCustomer}`,
					method: 'GET',
					params: body,
				};
			},
			providesTags: ['Sponsorships'],
		}),
		getSponsorshipContractById: build.query<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.getSponsorshipContractById}/${id}`,
					method: 'GET',
				};
			},
			providesTags: ['Sponsorships'],
		}),

		getSponsorshipContractRequestById: build.query<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.getSponsorshipRequestById}/${id}`,
					method: 'GET',
				};
			},
			providesTags: ['Sponsorships'],
		}),

		postSponsorshipContractActionById: build.query<any, any>({
			query: ({ id, action }) => {
				return {
					url: `${endpoints.postSponsorshipContractActionById}/${id}`,
					method: 'POST',
					body: { status_enum: action },
				};
			},
		}),

		postSponsorshipContractDeliveryActionById: build.query<any, any>({
			query: ({ id, action }) => {
				return {
					url: `${endpoints.postSponsorshipContractDeliveryActionById}/${id}`,
					method: 'POST',
					body: { status_enum: action },
				};
			},
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				await queryFulfilled;
				dispatch(queryAPI.util.invalidateTags(['Sponsorships']));
			},
		}),

		// PROJECT CONTRACTS QUERIES
		getAllProjectContractsAsSeller: build.query<any, any>({
			query: ({ ...params }) => {
				return {
					url: `${endpoints.getAllProjectContractsAsSeller}`,
					method: 'GET',
					params: params,
				};
			},
		}),
		getAllProjectContractsAsCustomer: build.query<any, any>({
			query: ({ ...params }) => {
				return {
					url: `${endpoints.getAllProjectContractsAsCustomer}`,
					method: 'GET',
					params: params,
				};
			},
		}),
		getProjectContractById: build.query<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.getProjectContractById}/${id}`,
					method: 'GET',
				};
			},
		}),
		getAllRequestCredits: build.query<any, any>({
			query: ({ ...params }) => {
				return {
					url: `${endpoints.getAllCreditRequests}`,
					method: 'GET',
					params: params,
				};
			},
		}),
		getAllRequestForAdmin: build.query<any, any>({
			query: ({ page, limit, search, startDate, endDate }) => {
				return {
					url: `${endpoints.adRequest}`,
					method: 'GET',
					params: {
						...(limit && { limit }),
						...(page && { page }),
						...(search && { search }),
						...(startDate && { start_date: startDate }),
						...(endDate && { end_date: endDate }),
					},
				};
			},
		}),
		postProjectContractActionById: build.query<any, any>({
			query: ({ id, action }) => {
				return {
					url: `${endpoints.postProjectContractActionById}/${id}`,
					method: 'POST',
					body: { status_enum: action },
				};
			},
		}),
		postProjectContractDeliveryActionById: build.query<any, any>({
			query: ({ id, action }) => {
				return {
					url: `${endpoints.postProjectContractDeliveryActionById}/${id}`,
					method: 'POST',
					body: { status_enum: action },
				};
			},
		}),

		getAdminCategories: build.query<any, any>({
			query: () => {
				return {
					url: endpoints.adminCategory,
					method: 'GET',
				};
			},
		}),

		getPersonalActivations: build.query({
			query: () => ({
				url: endpoints.getPersonalActivations,
			}),
		}),

		getEntityActivations: build.query({
			query: () => ({
				url: endpoints.getEntityActivations,
			}),
		}),

		getProjectMilestonesByContractId: build.query<any, any>({
			query: ({ id }) => {
				return {
					url: `${endpoints.getProjectMilestonesByContractId}/${id}`,
					method: 'GET',
				};
			},
		}),

		getContractsAnalysis: build.query<any, any>({
			query: () => {
				return {
					url: endpoints.getContractsAnalysis,
					method: 'GET',
				};
			},
		}),

		getAdminSubCategories: build.query<any, any>({
			query: () => {
				return {
					url: endpoints.adminSubCategory,
					method: 'GET',
				};
			},
		}),

		getAdminLevel: build.query<any, any>({
			query: () => {
				return {
					url: endpoints.adminLevel,
					method: 'GET',
				};
			},
		}),
		putProjectMilestonesAddFundByMilestoneId: build.query<any, any>({
			query: ({ id, body }) => {
				return {
					url: `${endpoints.putProjectMilestonesAddFundByMilestoneId}/${id}`,
					method: 'PUT',
					body,
				};
			},
		}),
		postAddNewProjectMilestone: build.query<any, any>({
			query: ({ body }) => {
				return {
					url: `${endpoints.postAddNewProjectMilestone}`,
					method: 'POST',
					body,
				};
			},
		}),
	}),
});

export const {
	useLazyGetAllRequestForAdminQuery,
	useGetAllRequestForAdminQuery,
	useLazyGetAllRequestCreditsQuery,
	useGetAllRequestCreditsQuery,
	useGetActiveAdsQuery,
	useLazyGetAdsBySpotsIdsQuery,
	useGetAdsBySpotsIdsQuery,
	useGetSettingsQuery,
	useGetSettingByKeyQuery,
	// useUpdateSettingsMutation,
	useGetAllWalletQuery,

	useLazyDownloadInvoiceQuery,

	useGetAllCreditsQuery,
	useGetEarnedCreditsByUserQuery,
	// getVNationSocialTimeLinePostsInteraction,
	useGetSubscriptionPlansQuery,
	useGetVNationSocialTimeLinePostsInteractionQuery,
	useLazyGetVNationSocialTimeLinePostsInteractionQuery,
	// getVVSearchLikeShare
	useLazyFindNotesByLessonIdQuery,
	useGetVVSearchLikeShareQuery,
	useLazyGetVVSearchLikeShareQuery,
	useGetVVSearchEndorsementQuery,
	useLazyGetVVSearchEndorsementQuery,
	useGetVVSearchJoinedGroupsQuery,
	useLazyGetVVSearchJoinedGroupsQuery,
	useGetVVSearchFollowedDataQuery,
	useLazyGetVVSearchFollowedDataQuery,
	useGetVVSearchGroupMembersByUserQuery,
	useLazyGetVVSearchGroupMembersByUserQuery,
	useGetVVSearchGalleryByUserIdQuery,
	useLazyGetVVSearchGalleryByUserIdQuery,
	useLazyGetVVSearchGalleryForPageAndGroupByIdVNationQuery,
	useGetVVSearchPostsByTypeByUserQuery,
	useLazyGetVVSearchPostsByTypeByUserQuery,
	useGetTimezonesQuery,
	useLazyGetTimezonesQuery,
	useWalletStatementsFundedAndWithdrawQuery,
	useLazyWalletStatementsFundedAndWithdrawQuery,
	useWalletBalanceQuery,
	useLazyWalletBalanceQuery,
	useWalletWithdrawRequestsQuery,
	useLazyWalletWithdrawRequestsQuery,
	useWalletStatementsQuery,
	useLazyWalletStatementsQuery,
	useUserPersonalProfileQuery,
	useLazyUserPersonalProfileQuery,
	useUserBusinessProfileQuery,
	useLazyUserBusinessProfileQuery,
	useMyRelatedAccountsQuery,
	useLazyMyRelatedAccountsQuery,
	useMyRelatedEntityProfilesQuery,
	useLazyMyRelatedEntityProfilesQuery,
	useCountriesQuery,
	useLazyCountriesQuery,
	useCitiesQuery,
	useLazyCitiesQuery,
	useLanguagesQuery,
	useLazyLanguagesQuery,
	useLanguageLevelsQuery,
	useLazyLanguageLevelsQuery,
	useLanguageLevelsCombineQuery,
	useLazyLanguageLevelsCombineQuery,
	useBlogPostCategoriesQuery,
	useLazyBlogPostCategoriesQuery,
	useLazyGetMyBlogPostsQuery,
	useGetMyBooksQuery,
	useLazyGetMyBooksQuery,
	useLazyGetMyBlogPostsByIdQuery,
	useGetMyBlogPostsQuery,
	useLazyAllPodcastsQuery,
	useJobPostCategoriesQuery,
	useLazyJobPostCategoriesQuery,
	useGetNotificationQuery,
	useLazyGetNotificationQuery,
	useNotificationCountQuery,
	useLazyNotificationCountQuery,
	useLazyToSeeNotificationQuery,
	useLazyGetUsersFullDetailsQuery,
	useGetHistoryQuery,
	useLazyGetHistoryQuery,
	useBusinessTypesQuery,
	useLazyBusinessTypesQuery,
	useLazyCollectiveConnectionsQuery,
	useLazyCollectiveFollowersQuery,
	useLazyCollectiveMembersQuery,
	useGeneralUsersQuery,
	useLazyGeneralUsersQuery,
	useLazyGetTop10VlanceServicesQuery,
	useLazyPlanMeetingQuery,
	useLazyPlanByIdQuery,
	useLazyDeletePlanByIdQuery,
	useGetNotesQuery,
	useLazyGetNotesQuery,
	useLazyFoldersQuery,
	useLazyFolderFilesQuery,
	useLazyFoldersFilesQuery,
	useFolderFilesQuery,
	useGetNewsQuery,
	useLazyGetNewsQuery,
	useGetAdSpotQuery,
	useLazyGetAdSpotQuery,
	useLazyGetAdRequestQuery,
	useGetAdRequestQuery,
	useLazyGetAdManageDataQuery,
	useGetAdManageDataQuery,
	useGetUserDataQuery,
	useLazyStorageSummaryQuery,
	useStorageSummaryQuery,
	useLazyMyStorageRecentlyUsedFilesQuery,
	useMyStorageRecentlyUsedFilesQuery,
	useLazyGetVEventsMyProfileCustomersQuery,
	useLazyServiceCategoriesQuery,
	useServiceCategoriesQuery,
	useLazyFolderDetailsQuery,
	useRoomsQuery,
	useLazyGetMyEventsByIdQuery,
	useLazyRoomsQuery,
	useLazyUserConversationsQuery,
	useGetAllCreditsDetailsQuery,
	useLazyConversationQuery,
	useLazyConversationMessagesQuery,
	useLazyConversationAttachmentsQuery,
	useLazyConversationPrivateNotesQuery,
	useLazyProjectCategoriesQuery,
	useGetAdSlotsQuery,
	useLazyGetAdSlotsQuery,
	useGetUserDetailQuery,
	useLazyGetUserDetailQuery,
	useLazyVEventsDownloadsQuery,
	useInviteUserQuery,
	useLazyInviteUserQuery,
	useGetPersonalOrEntityUsersQuery,
	useLazyGetPersonalOrEntityUsersQuery,
	useGetConnectedUsersIdsQuery,
	useLazyGetConnectedUsersIdsQuery,
	useGetConnectedUsersQuery,
	useLazyGetConnectedUsersQuery,
	useGetAllCustomersQuery,
	useLazyGetAllCustomersQuery,
	useGetAllCustomersByBookQuery,
	useLazyGetAllCustomersByBookQuery,
	useLazyGetAllCustomersByConsultationQuery,
	useGetUsersCountQuery,
	useLazyGetUsersCountQuery,
	useLazyGetAuthenticatedUserDetailsQuery,
	useGetAuthUserbirthdayQuery,
	useLazyGetAuthUserbirthdayQuery,
	useGetPersonalBirthdaysQuery,
	useLazyGetPersonalBirthdaysQuery,
	useGetEntityAnniversariesQuery,
	useLazyGetEntityAnniversariesQuery,
	useGetSuggestedUsersQuery,
	useLazyGetSuggestedUsersQuery,
	useGetConnectionRequestsQuery,
	useLazyGetConnectionRequestsQuery,
	useLazyGetMyEventsMyAssetsQuery,
	useLazyCollectiveJobsQuery,
	useLazyCollectiveClientsQuery,
	useLazyCollectiveServicesQuery,
	useLazyCollectiveSubscribersQuery,
	useLazyRoomConversationQuery,
	useLazyGetVTubeShortsByHashtagIdQuery,
	useLazyGetVTubeVideosByHashtagIdQuery,
	useRoomConversationQuery,
	useLazyRoomConversationMessagesQuery,
	useLazyRoomConversationAttachmentsQuery,
	useLazyRoomPrivateNotesQuery,
	useGetAuthenticatedUserDetailsQuery,
	useLazyGetUserDetailsQuery,
	useGetUserDetailsQuery,
	useLazyGetRoomCallDetailsQuery,
	useLazyGetCallDetailsQuery,
	useLazyChannelCategoriesQuery,
	useLazyRoomParticipantsQuery,
	useLazyVlanceAllClientsQuery,
	useGetAllEndorsmentsQuery,
	useLazyGetAllEndorsmentsQuery,
	useGetAllEndorsmentByIdQuery,
	useLazyGetAllEndorsmentByIdQuery,
	useGetAllEndorsmentsForUserQuery,
	useLazyGetAllEndorsmentsForUserQuery,
	useLazyVlanceMyProfileVlancerQuery,
	useGetAllProfileInteractionsQuery,
	useLazyGetAllProfileInteractionsQuery,
	useGetTop10UsersInteractionsQuery,
	useGetTop10BooksQuery,
	useLazyGetTop10BooksQuery,
	useLazyGetTop10UsersInteractionsQuery,
	useGetAuthUserProfileInteractionsQuery,
	useGetDownloadedBooksQuery,
	useLazyGetDownloadedBooksQuery,
	useLazyGetAuthUserProfileInteractionsQuery,
	useGetAuthUserProfileInteractionsByIdQuery,
	useLazyGetAuthUserProfileInteractionsByIdQuery,
	useLazyAllConversationsQuery,
	useGetTopAuthorsQuery,
	useLazyGetTopAuthorsQuery,
	useGetTopBookAuthorsQuery,
	useLazyGetTopBookAuthorsQuery,
	useGetTopBlogQuery,
	useLazyGetTopBlogQuery,
	useGetTopLikedBlogQuery,
	useLazyGetTopLikedBlogQuery,
	useGetTopSharedBlogQuery,
	useLazyGetTopSharedBlogQuery,
	useGetTopCommentedBlogQuery,
	useLazyGetTopCommentedBlogQuery,
	useGetTopAuthorsByCountryQuery,
	useLazyGetTopAuthorsByCountryQuery,
	useGetFeaturedAuthorsQuery,
	useLazyGetFeaturedAuthorsQuery,
	useGetFeaturedPostQuery,
	useLazyGetFeaturedPostQuery,
	useGetLatestPostQuery,
	useLazyGetLatestPostQuery,
	useLazySocialCategoriesQuery,
	useSocialCategoriesQuery,
	useGetBlogCategoryQuery,
	useLazyGetBlogCategoryQuery,
	useGetBookCategoryQuery,
	useLazyGetBookCategoryQuery,
	useGetHandpickedPerCategoryQuery,
	useLazyGetHandpickedPerCategoryQuery,
	useLazyGetAllModuleDetailsQuery,
	useLazyGetTop10UserDetailsQuery,
	useLazyMyJobsQuery,
	useMyJobsQuery,
	useLazyAllJobsQuery,
	useAllBooksQuery,
	useLazyAllBooksQuery,
	useAllJobsQuery,
	useLazyMyreferralsQuery,
	useGetDepartmentsQuery,
	useLazyGetDepartmentsQuery,
	useLazyGetDepartmentsRoleLevelQuery,
	useLazyGetMyApplicationsQuery,
	useGetAllBlogQuery,
	useLazyGetAllBlogQuery,
	useLazyGetReportsQuery,
	useLazyGetJobPostingQuery,
	useGetBlogByIdQuery,
	useLazyGetBlogByIdQuery,
	useGetBookByIdQuery,
	useLazyGetBookByIdQuery,
	useLazyVlanceMyAssetsProjectsQuery,
	useLazyVlanceFreelancerProjectsQuery,
	useLazyVlanceProjectsQuery,
	useLazyJobsEmployersQuery,
	useBookAuthorsQuery,
	useLazyBookAuthorsQuery,
	useLazyCandidatesQuery,
	useGetSimilarBlogSameAuthorQuery,
	useLazyGetSimilarBlogSameAuthorQuery,
	useGetSimilarBookSameAuthorQuery,
	useLazyGetSimilarBookSameAuthorQuery,
	useGetSimilarBookOtherAuthorQuery,
	useLazyGetSimilarBookOtherAuthorQuery,
	useGetSimilarBlogOtherAuthorQuery,
	useLazyGetSimilarBlogOtherAuthorQuery,
	useGetAllBlogPostInteractionsQuery,
	useLazyGetAllBlogPostInteractionsQuery,
	useGetAllBookInteractionsQuery,
	useLazyGetAllBookInteractionsQuery,
	useLazyChannelPlaylistsByChannelIdQuery,
	useSavedJobPostingsQuery,
	useLazySavedJobPostingsQuery,
	useLazySavedBooksQuery,
	useGetSavedUsersByModuleAssetNameMutation,
	useLazyPublishedJobsQuery,
	usePublishedJobsQuery,
	useGetEntityAnniversaryQuery,
	useLazyGetEntityAnniversaryQuery,
	useLazyVJobEmployeeCandidatesQuery,
	useLazyGetJobPostingInteractionsQuery,
	useLazyGetSimilarJobPostsBySameAuthorQuery,
	useLazyGetSimilarJobPostsByOtherAuthorsQuery,
	useGetMyProjectsQuery,
	useLazyGetMyProjectsQuery,
	useGetMyEventsQuery,
	useLazyGetMyEventsQuery,
	useLazyMessagesShowQuery,
	useLazyGetUserDataQuery,
	useLazyAllRoomMessageEmojisQuery,
	useLazyRoomMessagesShowQuery,
	useLazyUserModulesQuery,
	useLazyBlogAuthorsQuery,
	useLazyGetAllCastAuthorsQuery,
	useGetUpdateProfileInteractionMutation,
	useLazyShowJobPostingInteractionQuery,
	useLazyShowBlogPostInteractionQuery,
	useLazyShowBookInteractionQuery,
	useLazyShowConsultationInteractionQuery,
	useLazyGetUserBlogPostByIdQuery,
	useLazyGetBusinessDataQuery,
	useLazySavedSponsorShipQuery,
	useSavedConsultationsQuery,
	useLazySavedConsultationsQuery,
	useLazyGetBuzzAuthorsQuery,
	useLazyGetBuzzSponsorshipsQuery,
	useLazyVlanceServiceQuery,
	useLazyVlanceMyServiceQuery,
	useVlanceServiceByUserIdQuery,
	useLazyVlanceServiceByUserIdQuery,
	useVlanceGalleryByUserIdQuery,
	useLazyVlanceGalleryByUserIdQuery,
	useLazyGetMySponsorshipsQuery,
	useLazyGetSponsorShipByIdQuery,
	useLazyGetSponsorshipQuery,
	useGetCourseQuery,
	useLazyGetCourseQuery,
	useLazyGetSponsorshipInteractionsQuery,
	useLazyGetCourseInteractionsQuery,
	useLazyGetUnitLessonInteractionsQuery,
	useGetGraduationBookDataQuery,
	useLazyGetGraduationBookDataQuery,
	useGetPerformanceDataQuery,
	useLazyGetPerformanceDataQuery,
	useLazyShowSponsorshipInteractionQuery,
	useLazyShowCourseInteractionQuery,
	useLazyShowUnitLessonInteractionQuery,
	useLazyGetAllEventsQuery,
	useLazyGetOrganizersQuery,
	useGetSavedEventQuery,
	useLazyGetSavedEventQuery,
	useEventCategoriesQuery,
	useLazyEventCategoriesQuery,
	useLazyVlanceMyProfileProjectQuery,
	useLazyGetSponsorShipsTop10AuthorsQuery,
	useGetTop10InstructorsQuery,
	useLazyGetTop10InstructorsQuery,
	useGetConsultationTop10AuthorsQuery,
	useLazyGetConsultationTop10AuthorsQuery,
	useLazyGetSponsorShipsTop10Query,
	useGetCoursesTop10Query,
	useLazyGetCoursesTop10Query,
	useGetServicesTop10Query,
	useLazyGetServicesTop10Query,
	useGetLancersTop10Query,
	useLazyGetLancersTop10Query,
	useGetConsultationTop10Query,
	useLazyGetPlaylistByUserIdQuery,
	useLazyGetConsultationTop10Query,
	useGetSponsorShipsCategoriesQuery,
	useLazyGetSponsorShipsCategoriesQuery,
	useGetCollegeCategoriesQuery,
	useLazyGetCollegeCategoriesQuery,
	useGetLancerCategoriesQuery,
	useLazyGetLancerCategoriesQuery,
	useGetConsultationCategoriesQuery,
	useLazyGetConsultationCategoriesQuery,
	useGetAllCastInteractionsQuery,
	useLazyGetAllCastInteractionsQuery,
	useShowSingleinteractionQuery,
	useLazyShowSingleinteractionQuery,
	useGetAllEpisodesQuery,
	useLazyGetAllEpisodesQuery,
	useGetEpisodesByUserIdQuery,
	useLazyGetEpisodesByUserIdQuery,
	useGetSubscribersByUserIdQuery,
	useLazyGetSubscribersByUserIdQuery,
	useGetSavedMyAssetCastsQuery,
	useLazyGetSavedMyAssetCastsQuery,
	useGetSavedMyAssestEpisodesQuery,
	useLazyGetSavedMyAssestEpisodesQuery,
	useGetAllCastEpisodeInteractionsQuery,
	useLazyGetAllCastEpisodeInteractionsQuery,
	useLazyGetSponsorshipMyCustomersQuery,
	useGetCollegeMyCustomersQuery,
	useLazyGetCollegeMyCustomersQuery,
	useGetConsultationsMyCustomersQuery,
	useLazyGetConsultationsMyCustomersQuery,
	useLazyGetSponsorshipCustomersByIdQuery,
	useLazyGetPodcastsQuery,
	useLazyGetCandidatesByJobIdQuery,
	useLazyGetCandidatesByJobIdsBulkQuery,
	useTop10CastsQuery,
	useLazyTop10CastsQuery,
	useTop10CastAuthorsQuery,
	useLazyTop10CastAuthorsQuery,
	useLazyGetSponsorshipCustomersByUserIdQuery,
	usePodCastCategoriesQuery,
	useLazyPodCastCategoriesQuery,
	useLazyVBookDownloadRemoveQuery,
	useLazyDownloadBookQuery,
	useLazyGetEpisodesByPodCastIDQuery,
	useLazyGetEventDetailsByIdQuery,
	useLazyGetEventCategoriesQuery,
	useGetEventCategoriesQuery,
	useLazySimilarEventBySameAuthorQuery,
	useLazyGetCustomersByEventIdQuery,
	useGetCustomersByUserIdQuery,
	useLazyGetCustomersByUserIdQuery,
	useLazySimilarEventByOtherAuthorQuery,
	useLazyGetSimilarSponsorshipByOtherAuthorsQuery,
	useLazyGetSimilarSponsorshipBySameAuthorQuery,
	useLazyGetSimilarCourseBySameInstructorQuery,
	useLazyGetSimilarCourseByOtherInstructorQuery,
	useGetMyEventCustomersQuery,
	useLazyGetMyEventCustomersQuery,
	useLazyVlanceGalleryQuery,
	useLazyAllVCastQuery,
	useLazyGetCastByIdQuery,
	useLazyPodcastbySameAuthorQuery,
	useLazyPodcastbyOtherAuthorQuery,
	useLazyEpisodesByIdQuery,
	useLazyGetEventInteractionsQuery,
	useLazyShowEventInteractionQuery,
	useLazyGetSponsorshipByUserIdQuery,
	useLazyGetCoursesByUserIdQuery,
	useLazyGetEnrolledCoursesByUserIdQuery,
	useLazyVbookCustomersByUserQuery,
	useExpertsCustomersByUserQuery,
	useLazyExpertsCustomersByUserQuery,
	useLancerCustomersByUserQuery,
	useLazyLancerCustomersByUserQuery,
	useLazyBooksByUserQuery,
	useLazyConsultationsByUserQuery,
	useGetMyCoursesQuery,
	useLazyGetMyCoursesQuery,
	useLazyGetEnrolledCoursesQuery,
	useGetEventsByUserIdQuery,
	useLazyGetEventsByUserIdQuery,
	useGetPodcastsByUserIdQuery,
	useLazyGetPodcastsByUserIdQuery,
	useLazyStoreVExpertQuery,
	useLazyVExpertConsultationQuery,
	useVExpertsQuery,
	useLazyVExpertsQuery,
	useAllConsultationsQuery,
	useLazyAllConsultationsQuery,
	useGetAllConsultationsQuery,
	useLazyGetAllConsultationsQuery,
	useDownloadTicketFileQuery,
	useLazyDownloadTicketFileQuery,
	useLazyGetVvSearchSocialPagesQuery,
	useGetSimilarEpisodeBySameAuthorQuery,
	useGetSimilarEpisodeByOtherAuthorQuery,
	useLazyGetSimilarEpisodeByOtherAuthorQuery,
	useLazyGetSimilarEpisodeBySameAuthorQuery,
	useLazyGetVideoDetailsByVideoIdPlaylistQuery,
	useLazyGetSubscribersByPodcastIdQuery,
	useLazySubscribeUnSubscribePodcastQuery,
	useLazyGetMyPodcastsQuery,
	useLazyGetChannelsByIdQuery,
	useLazyGetConsultationByIdQuery,
	useDeleteConsultationByIdMutation,
	useCollectiveAllSubscribersQuery,
	useLazyCollectiveAllSubscribersQuery,
	useLazyChannelShortsByChannelIdQuery,
	useLazyChannelVideosByChannelIdQuery,
	useGetSubscribedPodcastsQuery,
	useLazyGetSubscribedPodcastsQuery,
	useGetSubscripersByPodcastIdQuery,
	useLazyGetSubscripersByPodcastIdQuery,
	useLazyGetEpisodeInteractionQuery,
	useLazyGetAllEpisodeInteractionsQuery,
	useLazyGetAllConsultationInteractionsQuery,
	useLazyGetHashtagsPerModuleQuery,
	useLazyGetTrendingHashtagsPerModuleQuery,
	useLazyGetMyChannelsByTypeQuery,
	useLazyGetVTubeVideosByHashtagQuery,
	useLazyGetAllVideosWithFilterQuery,
	useLazyGetSubscriptionsQuery,
	useLazyGetQueuedQuery,
	useLazyGetWatchLaterQuery,
	useLazyGetWatchTrackerQuery,
	useLazyGetSubscribedChannelsQuery,
	useLazyGetVTubeChannelsByHashtagQuery,
	useLazyGetVTubeChannelsByHashtagIdQuery,
	useLazyGetNominationSliderVideosQuery,
	useLazyGetFeaturedVideosQuery,
	useLazyGetFeaturedChannelsQuery,
	useLazyGetExclusiveChannelsQuery,
	useLazyGetFeaturedVAuthorsQuery,
	useLazyGetLiveVideosQuery,
	useLazyGetStreamedVideosQuery,
	useLazyGetAllChannelsQuery,
	useLazyGetAllShortsQuery,
	useLazyGetShortsByHashtagQuery,
	useLazyGetVideosByChannelIdQuery,
	useLazyGetVvSearchAuthorPageQuery,
	useLazyGetChannelInteractionsUsingChannelIdQuery,
	useLazyGetSubscribersByChannelIdQuery,
	useLazyGetVideoDetailsByVideoIdQuery,
	useLazyGetVideoInteractionsByVideoIdQuery,
	useLazyGetVTubeCategoriesQuery,
	useGetVTubeCategoriesQuery,
	useLazyGetTop10ChannelsQuery,
	useLazyGetTop10VTubeFeaturedAuthorsQuery,
	useLazyGetVtubeAuthorsQuery,
	useLazyGetSimilarConsultationOtherAuthorQuery,
	useLazyGetSimilarConsultationSameAuthorQuery,
	useLazyGetChannelInfoByChannelIdQuery,
	useLazyGetSimilarChannelsBySameAuthorQuery,
	useLazyGetSimilarChannelsByOtherAuthorQuery,
	useLazyGetSimilarVideosBySameAuthorQuery,
	useLazyGetSimilarVideosByOtherAuthorQuery,
	useLazyGetProjectByProjectIdQuery,
	useLazyGetProjectInteractionsQuery,
	useLazyGetServiceByServiceIdQuery,
	useLazyGetServicePostingInteractionsQuery,
	useLazyGetServiceReactionInteractionsQuery,
	useLazyGetServiceShareInteractionsQuery,

	useLazyGetProjectApplicantsByProjectIdQuery,
	useLazyGetProductCategoryQuery,
	useGetProductCategoryQuery,
	useGetVLanceCategoriesQuery,
	useGetTop10ServiesQuery,
	useGetTop10FreelancersQuery,
	useGetTop10ClientsQuery,
	useLazyGetTop10ClientsQuery,

	useLazyGetSimilarProjectsByOtherAuthorQuery,
	useLazyGetSimilarProjectsBySameAuthorQuery,

	useLazyGetSimilarServicesByOtherAuthorQuery,
	useLazyGetSimilarServicesBySameAuthorQuery,

	useLazyGetInvitationByIdQuery,
	useLazyGetInterviewInvitationQuery,
	useGetAllCouponsQuery,

	useLazyShowChannelInteractionByInteractionQueryQuery,
	useLazyGetAllSubscribersQuery,
	useLazyGetUserDataForAllModulesQuery,
	useLazyGetSubscribedChannelsByUserIdQuery,
	useLazyGetSubscribersByVAuthorIdQuery,
	useGetServiceAvailableSlotsQuery,
	useLazyGetServiceAvailableSlotsQuery,
	useLazyGetFeaturedShortsQuery,
	useLazyGetPlaylistsByChannelIdQuery,
	useLazyShowPlaylistByPlaylistIdQuery,
	useLazyVlanceCategoriesQuery,
	useVlanceCategoriesQuery,
	useLazyGetAllStoresQuery,
	useLazyTop10AuthorsStoreQuery,
	useLazyTop10AuthorsStoreByCountryQuery,
	useLazyTop10StoresQuery,
	useLazyGetSimilarStoresBySameAuthorQuery,
	useLazyGetSimilarStoresByOtherAuthorQuery,
	useLazyLatestStoresQuery,
	useLazyFeaturesAuthorsStoresQuery,
	useLazySavedDownloadMyAssetsQuery,
	useLazyShowStoreByIdQuery,
	useLazyGetStoreInteractionQuery,
	useLazyGetAllStorePostsQuery,
	useLazyGetAllProductsQuery,
	useLazyGetProductInterActionByIdQuery,
	useLazySavedDownloadMyProductsQuery,
	useLazyGetServiceInteractionsQuery,
	useLazyShowServiceInteractionQuery,
	useLazyGetSimilarServiceByOtherAuthorsQuery,
	useLazyGetSimilarServiceBySameAuthorQuery,
	useLazyVlanceServiceByIdQuery,
	useGetMyServicesQuery,
	useLazyGetMyServicesQuery,
	useLazyGetVLanceCategoriesQuery,
	useLazyShowProjectInteractionQuery,

	useLazyGetVmartCustomersQuery,

	useLazyGetAllCustomersByServiceIdQuery,
	useLazyGetFreelancersByUserIdQuery,
	useLazyGetVTubeRandomForSidebarQuery,
	useGetVTubeRandomForSidebarQuery,
	useLazyGetSidebarRandomVidoesAndPlaylistsQuery,
	useGetSidebarRandomVidoesAndPlaylistsQuery,
	useLazyGetNextRandomVideoQuery,
	useLazyShowCourseQuery,
	useLazyClearVideoHistoryQuery,
	useLazyToggleVideoHistoryQuery,
	useLazyGetSavedVideosOrShortsQuery,
	useLazyGetAuthorSubscribersForMyProfileQuery,
	useLazyGetProjectsByUserIdQuery,
	useLazyGetCoursesQuery,
	useLazyPostCourseEnrollQuery,
	useLazyGetQuizQuestionsQuery,
	useLazyGetExamQuestionsQuery,
	useLazyGetLessonByIdQuery,
	useLazyPutUpdateLessonTrackerQuery,
	useLazyGetVInstructorsQuery,

	useLazyTop10ProductsQuery,
	useLazyGetTop10AuthorsInProductQuery,

	useLazyGetCourseCustomersByCourseIdQuery,
	useLazyGetCourseCustomersByUserIdQuery,

	useLazyGetProductByIdQuery,
	useLazyGetSimilarProductsBySameAuthorQuery,
	useLazyGetSimilarProductsByOtherAuthorsQuery,
	useLazyGetAllProductPostsQuery,
	useLazyGetProductsByProductIdQuery,
	useLazyGetProductInteractionsQuery,
	useGetProductAuthorsQuery,
	useLazyGetProductAuthorsQuery,
	useLazyGetMyAttendeesQuery,
	useLazyGetUserAttendeesQuery,
	// WEBINAR QUERIES
	useLazyGetWebinarDetailsByIdQuery,
	useLazyGetAllWebinarInteractionsQuery,
	useLazyShowWebinarInteractionQuery,
	useLazyGetSimilarWebinarBySameAuthorQuery,
	useLazyGetSimilarWebinarByOtherAuthorsQuery,
	useLazyWebinarShowQuery,
	useLazyGetMyWebinarsQuery,
	useLazyGetWebinarAttendeesQuery,
	useLazyGetWebinarCategoriesQuery,
	useGetWebinarCategoriesQuery,
	useLazyGetWebinarTop10AuthorsQuery,
	useLazyGetUserWebinarsQuery,
	useLazyGetAllWebinarsQuery,
	useLazyGetAllPollsByWebinarIdQuery,
	useLazyPostCreateWebinarPollQuery,
	useLazyPostVoteOnPollAnswerQuery,
	useLazyUpdatePollByIdQuery,
	useLazyDeletePollByIdQuery,
	useLazyGetAllOffersByWebinarIdQuery,
	useLazyGetOfferByIdQuery,
	useLazyPostCreateWebinarOfferQuery,
	useLazyUpdateOfferByIdQuery,
	useLazyDeleteOfferByIdQuery,
	useLazyGetAllHandoutsByWebinarIdQuery,
	useLazyGetHandoutByIdQuery,
	useLazyPostCreateWebinarHandoutQuery,
	useLazyUpdateHandoutByIdQuery,
	useLazyDeleteHandoutByIdQuery,
	useLazyPostJoinAutomatedWebinarQuery,
	useLazyPostJoinHybridWebinarQuery,
	useLazyPostJoinLiveWebinarQuery,
	useLazyPutUpdateWebinarSettingsQuery,
	useLazyGetWebinarSettingsQuery,
	useLazyGetAttendeesByWebinarIdQuery,

	useLazyGetAllFreelancersByProjectIdQuery,
	useGetVNationAllGroupMembersQuery,
	useLazyGetVNationAllGroupMembersQuery,

	useGetVNationFollowerByUserIdQuery,
	useLazyGetVNationFollowerByUserIdQuery,
	useGetVNationGalleryByUserIdQuery,
	useLazyGetVNationGalleryByUserIdQuery,

	//v-nation
	useGetVNationFollowedDataQuery,
	useLazyGetVNationFollowedDataQuery,
	useLazyGetSocialTimelinePostInteractionQuery,
	useLazyGetTmelinePostsForHomePageVNationQuery,
	useLazyGetMyAssetsSocialPagesQuery,

	useLazyGetAllHostsQuery,
	useLazyGetAnnouncementByCourseQuery,
	useLazyGetUnitsByCourseQuery,
	useLazyGetPostsByHashtagsVNationQuery,
	useGetTop10SocialGroupsQuery,
	useGetTop10SocialPagesQuery,
	useGetVNationCategoriesQuery,
	useGetTop10AuthorsVNationQuery,
	useLazyGetTop10AuthorsVNationQuery,
	useGetHashtagsPerModuleQuery,

	useLazyGetMyAssetsSocialGroupsQuery,
	// useLazyGetSearchGroupsVNationQuery,
	useLazyGetSearchGroupsForVNationQuery,
	useLazyGetPostByGroupIdVNationQuery,
	useLazyGetPostByPageIdVNationQuery,
	useLazyGetGroupMembersByGroupIdQuery,
	useLazyGetPageFollowersByPageIdQuery,
	useLazyNewGetPageFollowersByPageIdQuery,
	useLazyGetGroupDataByGroupIdQuery,
	useLazyGetSocialPageDataByPageIdQuery,
	useLazyGetSocialGroupInteractionQuery,
	useLazyGetSocialPageInteractionsQuery,
	useLazyShowSocialGroupInteractionQuery,
	useLazyShowSocialPageInteractionQuery,

	useLazyGetProductCustomerByIdQuery,
	useLazyGetUserProductsQuery,

	useGetWishlistsQuery,
	useLazyGetWishlistsQuery,
	useLazyGetStoreByIdQuery,
	useGetStoresByUserIdQuery,
	useLazyGetStoresByUserIdQuery,
	useGetCustomersByProductIdQuery,
	useLazyGetCustomersByProductIdQuery,
	useGetProductByStoreIdQuery,
	useLazyGetProductByStoreIdQuery,
	useGetCustomersByStoreIdQuery,
	useLazyGetCustomersByStoreIdQuery,
	useLazyGetCartSummaryQuery,
	useLazyClearCartQuery,
	useLazyGetShippingAddressQuery,
	useLazyGetShippingOptionsByIdQuery,
	useLazyGetOrCreateConversationQuery,
	useGetProductCategoriesQuery,
	useLazyGetProductCategoriesQuery,
	useLazyShowSocialPageQuery,
	useLazyShowSocialGroupQuery,
	useLazyGetSocialGroupPostInteractionQuery,
	useLazyShowSocialGroupPostInteractionQuery,
	useLazyGetSocialPagePostInteractionQuery,
	useLazyShowSocialPagePostInteractionQuery,
	useLazyShowSocialTimelinePostInteractionQuery,
	useLazyShowProductsByStoreIdQuery,
	useLazyGetChannelsByUserIdQuery,
	useLazyGetMySubscripersQuery,
	useLazyInstructorsByCourseQuery,
	useLazyGetShareTimelinesQuery,
	useLazyVlanceSearchlancersQuery,
	useLazyVlanceSearchAllClientsQuery,
	useLazyGetSimilarGroupByOtherGroupIdQuery,
	useLazyGetSimilarGroupBySameGroupIdQuery,
	useLazyGetSimilarSocialPageByOtherPageIdQuery,
	useGetMyReferralsQuery,
	// useGetPlansQuery,
	useLazyGetPlansQuery,
	useLazyGetSimilarSocialPageBySamePageIdQuery,
	useLazyGetMyWalletQuery,
	useGetWalletStatementsQuery,
	useLazyGetWalletStatementsQuery,
	useGetAdminBadgesQuery,
	useLazyGetOrdersQuery,
	useGetOrdersItemsQuery,
	useGetDataByAssetQuery,
	useLazyGetDataByAssetQuery,
	useLazyGetAdSpotsQuery,
	useGetReportsForAdminQuery,
	useLazyGetReportByIdQuery,
	// CONTRACTS
	// SERVICE CONTRACTS EXPORTS
	useLazyGetAllServiceContractsAsSellerQuery,
	useLazyGetAllServiceContractsAsCustomerQuery,
	useLazyGetServiceContractByIdQuery,
	useLazyPostServiceContractActionByIdQuery,
	useLazyPostServiceContractDeliveryActionByIdQuery,
	useLazyGetServiceContractRequestByIdQuery,
	//SPONSORSHIP CONTRACTS EXPORTS
	useLazyGetAllSponsorshipContractsAsSellerQuery,
	useLazyGetAllSponsorshipContractsAsCustomerQuery,
	useLazyGetSponsorshipContractByIdQuery,
	useLazyPostSponsorshipContractActionByIdQuery,
	useLazyPostSponsorshipContractDeliveryActionByIdQuery,
	useLazyGetSponsorshipContractRequestByIdQuery,

	//PROJECT CONTRACTS EXPORTS
	useLazyGetAllProjectContractsAsSellerQuery,
	useLazyGetAllProjectContractsAsCustomerQuery,
	useLazyGetProjectContractByIdQuery,
	useLazyPostProjectContractActionByIdQuery,
	useLazyPostProjectContractDeliveryActionByIdQuery,

	//CONTRACTS ANALYSIS
	useLazyGetContractsAnalysisQuery,

	// ADMIN PANEL CATEGORIES
	useGetAdminCategoriesQuery,
	useGetAdminSubCategoriesQuery,
	useGetAdminLevelQuery,
	useLazyGetProjectMilestonesByContractIdQuery,
	useLazyPutProjectMilestonesAddFundByMilestoneIdQuery,
	useLazyPostAddNewProjectMilestoneQuery,
	useGetPersonalActivationsQuery,
	useLazyGetPersonalActivationsQuery,
	useLazyGetEntityActivationsQuery,

	useGetRecentlyUsedFoldersQuery,
	useLazyGetRecentlyUsedFoldersQuery,
} = queryAPI;
